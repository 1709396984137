import React from 'react';
import MaterialButton, { ButtonProps } from '@mui/material/Button';
import { makeStyles } from 'tss-react/mui';

type Props = ButtonProps & {
  carrier?: boolean;
};

const useStyles = makeStyles()(({ palette }) => ({
  button: {
    background: `linear-gradient(99.7deg, ${palette.primary.main} -19.43%, ${palette.secondary.main} 80.93%)`,
    boxShadow: '0px 11px 12px -10px rgba(4, 105, 221, 0.7)',
    '&.Mui-disabled': {
      background: `${palette.grey[400]}`,
    },
    '& span': {
      marginLeft: 15,
      marginRight: 15,
    },
  },
}));

export const Button: React.FC<Props> = ({ carrier, className, ...rest }) => {
  const { classes, cx } = useStyles();
  return (
    <MaterialButton
      variant={carrier ? 'contained' : rest.variant}
      className={carrier ? cx(classes.button, className) : className}
      {...rest}
    />
  );
};
