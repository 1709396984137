import { Link as RouterLink } from 'react-router-dom';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Breadcrumbs, Typography, Link } from '@mui/material';

export const Breadcrumb: React.FC<{ breadcrumbItems: any[] }> = ({
  breadcrumbItems,
}) => {
  return (
    <Breadcrumbs
      separator={<ArrowForwardIosIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      {breadcrumbItems.map((item, index) => {
        const isLastItem = index + 1 === breadcrumbItems.length;

        return !isLastItem ? (
          <Link component={RouterLink} key={index} to={item.link}>
            <Typography color="grey">{item.label}</Typography>
          </Link>
        ) : (
          <Typography key={index} color="primary">
            {item.label}
          </Typography>
        );
      })}
    </Breadcrumbs>
  );
};
