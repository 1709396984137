import { Button, ButtonGroup, Grid, Typography } from '@mui/material';
import React from 'react';

type Props = {
  label: string;
  items: any[];
  error?: string;
  value: string[];
  handleOnChange: (props: any) => void;
};

export const ButtonSwitch: React.FC<Props> = ({
  label,
  items,
  error,
  value,
  handleOnChange,
}) => (
  <Grid container style={{ marginBottom: 5 }}>
    <Grid item xs={12}>
      <Typography>{label}</Typography>
    </Grid>
    <Grid item xs={12}>
      <ButtonGroup variant="contained">
        {items.map((item, index) => (
          <Button
            color={item.id !== value ? 'inherit' : 'secondary'}
            value={item.id}
            onClick={handleOnChange}
            key={index}
          >
            {item.label}
          </Button>
        ))}
      </ButtonGroup>
    </Grid>
  </Grid>
);
