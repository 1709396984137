import { useState, useEffect, useRef } from 'react';

import SaveSvg from '@mui/icons-material/Save';
import { arrayMove, useDropSetup } from '@vscom/utils';
import { Button } from '@vscom/components';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { toast } from 'react-toastify';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import { useAppContext } from '../../context/AppContext';
import { ModalHeader } from './Header';
import { FormWrapper } from './FormWrapper';
import { Panel } from '../../types';
import { useDrag } from 'react-dnd';
import { dbApiUpdateItem } from '../../api/crudCalls';
import { buildPanelName } from '../../utils/panelsIndexBuilder';

export const SortModal = ({
  panelsData,
  onClose,
}: {
  panelsData: Panel[];
  onClose: any;
}) => {
  const [state, setState] = useAppContext();
  const [panels, setPanels] = useState<Panel[]>(panelsData);

  useEffect(() => {
    setPanels(panelsData);
  }, [panelsData, state.sortModalVisible]);

  const saveNewOrder = async (e: any) => {
    e.preventDefault();
    try {
      // Create an array of PanelIDs based on the current order of panels in the state
      await Promise.all(
        panels.map(async (panel, i) => {
          return await dbApiUpdateItem(
            'panels',
            {
              name: buildPanelName(panel.Name, i),
              points: panel.Points,
              cleanPoints: panel.CleanPoints,
            },
            panel.PanelID
          );
        })
      );
      onClose();
      setState({ sortModalVisible: false });
      toast.success('Order updated successfully!');
    } catch (error) {
      toast.error('Failed to update order.');
      console.error('Error updating panel order:', error);
    }
  };

  const movePanel = (dragIndex: any, hoverIndex: any) => {
    setPanels(arrayMove([...panels], dragIndex, hoverIndex));
  };

  return (
    <Drawer anchor="right" open={state.sortModalVisible}>
      <ModalHeader title="Order Panels" modalKey="sortModalVisible" />

      <List>
        {panels.map((panel, index) => (
          <SingleItem
            key={panel.PanelID}
            panel={panel}
            index={index}
            movePanel={movePanel}
          />
        ))}
      </List>

      <FormWrapper>
        <Button
          fullWidth
          carrier
          onClick={saveNewOrder}
          startIcon={<SaveSvg />}
          type="submit"
          disabled={false}
        >
          Save Order
        </Button>
      </FormWrapper>
    </Drawer>
  );
};

const SingleItem = ({
  panel,
  index,
  movePanel,
}: {
  panel: Panel;
  index: number;
  movePanel: any;
}) => {
  const previewRef = useRef<HTMLDivElement>(null);

  // ============== DRAG & DROP ==============
  // init the drag & drop system
  const [{ opacity }, drag, preview] = useDrag({
    type: 'PANEL',
    item: () => {
      return { type: 'PANEL', index };
    },
    collect: (monitor: any) => ({
      opacity: monitor.isDragging() ? 0 : 1,
    }),
  });

  const [{ handlerId }, drop] = useDropSetup({
    type: 'PANEL',
    previewRef: previewRef,
    index,
    moveObject: movePanel,
  });

  drag(drop(preview(previewRef)));
  // ============== END DRAG & DROP ==============

  return (
    <div data-handler-id={handlerId} ref={previewRef}>
      <ListItem
        key={panel.PanelID}
        style={{
          border: '1px dashed black',
          opacity,
          marginBottom: 10,
          cursor: 'move',
        }}
      >
        <ListItemIcon style={{ cursor: 'move' }}>
          <DragIndicatorIcon />
        </ListItemIcon>
        <ListItemText primary={panel.Name} />
      </ListItem>
    </div>
  );
};
