// Function to build a string from an index and panel name
export function buildPanelName(Name: string, index: number): string {
  return `@{${index}}-${Name}`;
}

// Function to parse the string and return an object with the index and panel name
export function getPanelName(input: string): { index?: number; Name: string } | null {
  const regex = /@\{(\d+)\}-(.*)/;
  const match = input.match(regex);

  if (match) {
    const index = parseInt(match[1], 10);
    const Name = match[2];
    return { index, Name };
  }
  return { index: undefined, Name: input }; // or throw an error, depending on how you want to handle unmatched inputs

}