export const MuiGrid = {
  defaultProps: {
    size: "small",
  },
  styleOverrides: {
    item: {
      // "& .MuiOutlinedInput-root": {
      //   paddingTop: "10px !important",
      // },
    },
  },
}
export const MuiTableCell = {
  defaultProps: {
  },
  styleOverrides: {
    root: {
      paddingTop: 5,
      paddingBottom: 5,
    },
  },
}
