import React, { useState, useEffect } from 'react';
import { useAppContext } from '../../context/AppContext';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button } from '@vscom/components';
import { Drawer, Typography } from '@mui/material';

import PlusSvg from '@mui/icons-material/Add';
import BinSvg from '@mui/icons-material/Delete';
import SaveSvg from '@mui/icons-material/Save';

import { Input } from '../layout/Input';
import { TeamSelect } from '../layout/select/TeamSelect';
import { ModalHeader } from './Header';
import { FormWrapper } from './FormWrapper';
import {
  dbApiAddItem,
  dbApiDeleteItem,
  dbApiUpdateItem,
} from '../../api/crudCalls';

const projectValidationSchema = Yup.object().shape({
  projectName: Yup.string().required(`Please enter a project name`),
});

const getUniqueListBy = (arr: any[], key: string) => [
  ...new Map(arr.map((item: any) => [item[key], item])).values(),
];

type Props = {
  id?: number;
  name: string;
  descr: string;
  team: any;
  owner: any;
  forceUpdate: () => void;
};

const INIT_STATE = {
  projectName: '',
  projectDescription: '',
  teamError: false,
};

export const ProjectModal: React.FC<Props> = ({
  id,
  name,
  descr,
  team,
  owner,
  forceUpdate,
}) => {
  const [state, setState] = useAppContext();

  const [projectData, setProjectData] = useState(INIT_STATE);

  const [userList, setUserList] = useState<any[]>([]);
  const [contributor, setContributor] = useState<any[]>([]);
  const [reader, setReader] = useState<any[]>([]);
  const [projectOwner, setProjectOwner] = useState<any>();

  const resetProjectData = () => {
    setProjectData(INIT_STATE);
    setContributor([]);
    setReader([]);
    setProjectOwner(undefined);
  };

  useEffect(() => {
    const usersTeamList = projectOwner
      ? [...contributor, ...reader, projectOwner]
      : [...contributor, ...reader];
    const a = getUniqueListBy(usersTeamList, 'userID');
    const b = usersTeamList;
    setState({ loading: a.length !== b.length });
    setProjectData({
      ...projectData,
      teamError: a.length !== b.length,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contributor, reader, projectOwner]);

  useEffect(() => {
    if (state.appUsersList) {
      const m = [
        ...state.appUsersList.map((user) => ({
          userID: user.userID,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email.toLowerCase(),
        })),
      ];
      setUserList(m);
    }
  }, [state?.appUsersList]);

  useEffect(() => {
    if (owner) {
      setProjectOwner({
        userID: owner.userID,
        firstName: owner.firstName,
        lastName: owner.lastName,
        email: owner.email.toLowerCase(),
      });
    } else {
      state?.userInfos &&
        setProjectOwner({
          userID: state?.userInfos.userId,
          firstName: state?.userInfos.firstName,
          lastName: state?.userInfos.lastName,
          email: state?.userInfos.emailAddress.toLowerCase(),
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team, owner, state?.userInfos?.userId]);

  useEffect(() => {
    setProjectData({
      ...projectData,
      projectName: name,
      projectDescription: descr,
    });
    if (team?.length) {
      setContributor(team.filter((el: any) => el.Role === 'contributor'));
      setReader(team.filter((el: any) => el.Role === 'reader'));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, descr, team]);

  const hideModal = () => {
    setState({ projectModalVisible: false });
    resetProjectData();
  };

  const refreshData = () => {
    hideModal();
    forceUpdate();
  };

  const deleteProject = async () => {
    setState({ loading: true });

    try {
      await dbApiDeleteItem('projects', Number(id), state.userInfos.userId);
      setState({ loading: false });
      setTimeout(refreshData, 300);
    } catch (error) {
      setState({ loading: false });
    }
  };

  const addProject = async (values: any) => {
    setState({ loading: true });
    const data = {
      name: values.projectName,
      description: values.projectDescription,
      owner: projectOwner?.userID,
      team: [...contributor, ...reader],
    };
    try {
      await dbApiAddItem('projects', data);
      setState({ loading: false });
      resetProjectData();
      setTimeout(refreshData, 300);
    } catch (error) {
      setState({ loading: false });
    }
  };

  const saveProject = async (values: any) => {
    setState({ loading: true });
    const data = {
      name: values.projectName,
      description: values.projectDescription,
      team: [...contributor, ...reader],
      owner: projectOwner?.userID,
    };

    try {
      await dbApiUpdateItem('projects', data, Number(id));
      setState({ loading: false });
      resetProjectData();
      setTimeout(refreshData, 300);
    } catch (error) {
      setState({ loading: false });
    }
  };

  const contributorOnChangeHandle = (value: any[]) => {
    console.log('contributorOnChangeHandle ', value);
    let userInfo = [];
    if (value?.length) {
      userInfo = value.map((user: any) => ({
        ...user,
        Role: 'contributor',
      }));
    }
    setContributor(userInfo);
  };

  const readerOnChangeHandle = (value: any[]) => {
    let userInfo = [];
    if (value?.length) {
      userInfo = value.map((user) => ({
        ...user,
        Role: 'reader',
      }));
    }
    setReader(userInfo);
  };

  const ownerOnChangeHandle = (value: any) => {
    setProjectOwner(value);
  };

  const handleChange = (event: any) => {
    const {
      target: { value, name },
    } = event;
    setProjectData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const submitForm = (values: any, { setSubmitting }: any) => {
    setSubmitting(true);
    name ? saveProject(values) : addProject(values);
  };

  return (
    <Drawer anchor="right" open={state.projectModalVisible}>
      <ModalHeader
        title={!name ? 'New Project' : `Edit ${name}`}
        modalKey="projectModalVisible"
        onClose={resetProjectData}
      />

      <Formik
        enableReinitialize={true}
        initialValues={projectData}
        validationSchema={projectValidationSchema}
        onSubmit={submitForm}
      >
        {({ values, errors, touched, handleSubmit }) => {
          return (
            <FormWrapper onSubmit={handleSubmit}>
              <div>
                <Input
                  id="projectName"
                  label="Project name"
                  name="projectName"
                  value={values.projectName}
                  onChange={handleChange}
                  required={true}
                  disabled={state.isReader}
                  error={
                    errors.projectName &&
                    touched.projectName &&
                    errors.projectName
                  }
                />
                <Input
                  id="projectDescription"
                  label="Project description"
                  name="projectDescription"
                  disabled={state.isReader}
                  value={values.projectDescription}
                  onChange={handleChange}
                />
                {userList && name && (
                  <TeamSelect
                    id="ownerSelect"
                    label="Owner"
                    isMulti={false}
                    users={userList}
                    isDisabled={!state.isContributor}
                    selectedUsers={[projectOwner]}
                    onChange={ownerOnChangeHandle}
                  />
                )}

                {userList && (
                  <TeamSelect
                    id="contributorSelect"
                    label="Contributor"
                    isDisabled={state.isReader}
                    users={userList}
                    selectedUsers={contributor}
                    onChange={contributorOnChangeHandle}
                  />
                )}
                {userList && (
                  <TeamSelect
                    id="readerSelect"
                    label="Reader"
                    isDisabled={state.isReader}
                    users={userList}
                    selectedUsers={reader}
                    onChange={readerOnChangeHandle}
                  />
                )}

                {projectData.teamError && (
                  <Typography color="error">
                    The role must be unique per person.
                  </Typography>
                )}
              </div>
              {!state.isReader && (
                <div>
                  {name && (
                    <Button
                      fullWidth
                      variant="contained"
                      color="error"
                      style={{ marginTop: 10 }}
                      startIcon={<BinSvg />}
                      onClick={deleteProject}
                      disabled={state.loading}
                      type="button"
                    >
                      Delete project
                    </Button>
                  )}

                  <Button
                    fullWidth
                    carrier
                    style={{ marginTop: 10 }}
                    startIcon={name ? <SaveSvg /> : <PlusSvg />}
                    type="submit"
                    disabled={state.loading}
                  >
                    {name ? 'Save project' : 'Add project'}
                  </Button>
                </div>
              )}
            </FormWrapper>
          );
        }}
      </Formik>
    </Drawer>
  );
};
