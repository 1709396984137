import { useEffect, useRef, useState } from 'react';
// Filter key stroke to be only 0-1 inputs
import { TextField } from '@mui/material';
import { BitFieldsDropdown } from './BitFieldsDropdown';
import isKeyCodeBinary from '../../utils/isKeyCodeBinary';
import { useBoolean } from '@vscom/utils';

function dec2bin(dec: number) {
  return (dec >>> 0).toString(2);
}

/**
 * BinView component
 *
 * @param id - The ID of the component
 * @param value - The decimal value to be converted to binary
 * @param pointWriteCall - The function to call after the binary value is changed
 * @param bitFields - The bit fields for the binary value
 * @returns JSX.Element
 */
export const BinView: React.FC<{
  id: string;
  value: number;
  pointWriteCall: (hsBody: string) => void;
  bitFields: any;
  disabled?: boolean;
}> = ({ id, value, pointWriteCall, bitFields, disabled }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  // Values
  const [currentValue, setCurrentValue] = useState<string>(dec2bin(value)); // Binary value
  const {
    value: changedValue,
    setFalse: resetChanged,
    setTrue: setChanged,
  } = useBoolean(false);

  useEffect(() => {
    if (!changedValue) {
      setCurrentValue(dec2bin(value));
      if (inputRef.current) {
        inputRef.current.value = dec2bin(value);
      }
    }
  }, [value]);
  const onChangeComplete = () => {
    resetChanged();
    setTimeout(() => {
      const hsBody = `ver:"2.0"\nid,level,val,who\n@${id},1,${parseInt(currentValue, 2)},"WebUI"\n`;
      pointWriteCall(hsBody);
    }, 250);
  };

  const onBitValueChange = (newValue: number) => {
    setChanged();
    setCurrentValue(dec2bin(newValue));
  };

  const handleBinaryInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChanged();
    setCurrentValue(dec2bin(parseInt(e.target.value, 2)));
    if (inputRef.current) {
      inputRef.current.value = dec2bin(parseInt(e.target.value, 2));
    }
  };

  const handleBinaryInputKeyDown = (e: React.KeyboardEvent) => {
    const keyCode = e.keyCode || e.which;
    setChanged();

    if (!isKeyCodeBinary(keyCode)) {
      e.preventDefault();
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        maxWidth: 400,
        alignItems: 'center',
        justifyContent: 'end',
      }}
    >
      <BitFieldsDropdown
        bitFields={bitFields}
        currentValue={currentValue}
        onBitValueChange={onBitValueChange}
        handleCloseDropdown={onChangeComplete}
        disabled={disabled}
      />
      <TextField
        inputRef={inputRef}
        variant="outlined"
        size="small"
        disabled={disabled}
        className="viewpoint"
        value={currentValue}
        onChange={handleBinaryInputChange}
        onBlur={onChangeComplete}
        onKeyDown={handleBinaryInputKeyDown}
      />
    </div>
  );
};
