import appConfig from '../appConfig'
import { callApi } from './callApi'

const authorization = 'Bearer ' + localStorage.getItem('msal.idtoken')
const getUniqueListBy = (arr: any[], key: any) => [...new Map(arr.map((item) => [item[key], item])).values()]

const getUserProfile = async () => await callApi({
    method: 'get',
    url: `https://api.carrierappshub.com/api/users/GetUserProfileWithAccessPermissions?appurl=${appConfig.login.uiUrl}`,
    headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: authorization,
    },
})
const getUsersFromPermission = async () => {
    const response = await callApi({
        method: 'post',
        url: `https://api.carrierappshub.com/api/users/GetAllUsersByPermissionName`,
        data: {
            Permission: 'VS COM',
            GroupInfo: false,
            UserActivityInfo: false,
        },
        headers: {
            Authorization: authorization,
        },
    })
    const orderedUsersArray = getUniqueListBy(response.usersDetails, 'userID').sort((a, b) =>
        // eslint-disable-next-line no-nested-ternary
        a.firstName < b.firstName ? -1 : a.firstName > b.firstName ? 1 : 0
    )
    return orderedUsersArray
}

export { getUserProfile, getUsersFromPermission }
