import { useEffect, useState } from 'react';
import { useAppContext } from '../../context/AppContext';
import axios from 'axios';
import { formatZincToJson } from '../../utils/formatZinc';

export const Footer = () => {
  const [state] = useAppContext();
  const [hsVersionNumber, setHsVersionNumber] = useState(null);
  const version = process.env.REACT_APP_VERSION;

  useEffect(() => {
    axios
      .get(`${state.hsServerUrl}/nav`, {
        headers: { Accept: 'application/json' },
      })
      .then(function (response) {
        const hsResponse = formatZincToJson(response.data);

        setHsVersionNumber(hsResponse.rows[0].dis);
      })
      .catch(function (error) {
        // handle error
        console.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <footer
      style={{
        position: 'fixed',
        bottom: 0,
        right: 0,
        display: 'flex',
        flexDirection: 'column',
        padding: 5,
      }}
    >
      <small>Client version: {version}</small>
      {hsVersionNumber && <small>{hsVersionNumber}</small>}
    </footer>
  );
};
