import appConfig from "../appConfig"
import { callApi } from "./callApi";

export const dbApiGetItem = async (item: string, id: number | string) => await callApi({
  method: 'get',
  url: `${appConfig.api.dbApiUrl}/${item}/${id}`,
  headers: appConfig.api.auth,
})

export const dbApiAddItem = async (item: string, body: any) => await callApi({
  method: 'post',
  url: `${appConfig.api.dbApiUrl}/${item}`,
  headers: appConfig.api.auth,
  data: body,
})

export const dbApiUpdateItem = async (item: string, body: any, id: number | string) => await callApi({
  method: 'put',
  url: `${appConfig.api.dbApiUrl}/${item}/${id}`,
  headers: appConfig.api.auth,
  data: body,
})

export const dbApiDeleteItem = async (item: string, id: number | string, userID: number | string) => await callApi({
  method: 'delete',
  url: `${appConfig.api.dbApiUrl}/${item}/${id}`,
  headers: appConfig.api.auth,
  data: {
    userID,
  },
})