import { useEffect, useRef, useState } from 'react';
import Slider from '@mui/material/Slider';
import { ButtonGroup, TextField, Typography } from '@mui/material';
import { useBoolean } from '@vscom/utils';
import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const hide = {
  '-webkit-appearance': 'none',
  margin: 0,
};

const useStyle = makeStyles()(() => ({
  wrapper: {
    display: 'flex',
    width: '100%',
    maxWidth: 400,
    alignItems: 'center',
    marginLeft: 30,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
    '& button': {
      minWidth: '20px !important',
      width: 5,
      borderColor: '#c4c4c4 !important',
      maxWidth: 5,
      minHeight: 12,
      height: 12,
      padding: 0,
      margin: 0,
      '&:first-of-type': {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
      },
      '&:last-child': {
        borderBottomLeftRadius: 3,
        borderBottomRightRadius: 3,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        marginRight: -1,
      },
      '& svg': {
        margin: 0,
      },
    },
  },
  input: {
    marginLeft: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    /* Chrome, Safari, Edge, Opera */
    '& input::-webkit-outer-spin-button': hide,
    '& input::-webkit-inner-spin-button': hide,

    /* Firefox */
    '& input[type=number]': {
      ' -moz-appearance': 'textfield',
    },
  },
}));

interface InputRangeSliderProps {
  id: string;
  min: number;
  max: number;
  value: number;
  unit: string;
  disFmt: 'Bin' | 'Hex' | string; // Assuming 'Bin' and 'Hex' are the only special formats, otherwise use string
  onChangeComplete: (value: number | string) => void; // Callback function type depends on whether you expect to pass number or string
  forwardedRef: React.Ref<any>; // Specify more specific type if possible
  disabled: boolean;
}

let timeoutRef: any = null;

/**
 * InputRangeSlider component.
 *
 * @param {string} id - The id of the component.
 * @param {number} min - The minimum value of the slider.
 * @param {number} max - The maximum value of the slider.
 * @param {number} value - The current value of the slider.
 * @param {string} unit - The unit of measurement for the value.
 * @param {string} disFmt - The display format for the value (e.g. 'Bin', 'Hex').
 * @param {function} onChangeComplete - The callback function to be called when the value is changed and the user completes the interaction.
 * @param {object} forwardedRef - The ref object to be forwarded to the Slider component.
 * @param {boolean} disabled - Whether the component is disabled or not.
 * @returns {JSX.Element} The rendered InputRangeSlider component.
 */
const InputRangeSlider: React.FC<InputRangeSliderProps> = ({
  id,
  min,
  max,
  value,
  unit,
  disFmt,
  onChangeComplete,
  forwardedRef,
  disabled,
}) => {
  const { classes } = useStyle();
  const inputRef = useRef<any>(null);
  // Values
  const [currentValue, setCurrentValue] = useState<number>(value); // Decimal
  const {
    value: changedValue,
    setFalse: resetChanged,
    setTrue: setChanged,
  } = useBoolean(false);

  useEffect(() => {
    if (!changedValue) {
      setCurrentValue(value);
    }
  }, [value]);

  const handleKeyDown = ({ key }: any) => {
    if (key === 'Enter') {
      onChangeComplete(currentValue);
      resetChanged();
    }
  };

  const handleLoseFocus = ({ target }: any) => {
    if (Number(target.value) !== Number(value)) {
      onChangeComplete(target.value);
      resetChanged();
    }
  };

  const handleChangeSlider = (event: any, newValue: number | number[]) => {
    setCurrentValue(newValue as number);
    setChanged();
    if (disFmt === 'Bin') {
      inputRef.current.value = newValue.toString(2);
    } else if (disFmt === 'Hex') {
      inputRef.current.value = newValue.toString(16);
    } else {
      inputRef.current.value = newValue;
    }
  };

  const handleInputOnChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setChanged();
    if (value > max) {
      setCurrentValue(max);
      inputRef.current.value = max;
    } else if (value < min) {
      setCurrentValue(min);
      inputRef.current.value = min;
    } else {
      setCurrentValue(value);
      inputRef.current.value = value;
    }
  };

  const handleIncrement = () => {
    let newValue = Number(inputRef.current.value) + 1;
    if (newValue > max) {
      newValue = max;
    }
    setCurrentValue(newValue);
    if (timeoutRef) {
      clearTimeout(timeoutRef);
    }
    timeoutRef = setTimeout(() => {
      handleLoseFocus({ target: { value: newValue } });
    }, 1000);
  };

  const handleDecrement = () => {
    let newValue = Number(inputRef.current.value) - 1;
    if (newValue < min) {
      newValue = min;
    }
    setCurrentValue(newValue);
    if (timeoutRef) {
      clearTimeout(timeoutRef);
    }
    timeoutRef = setTimeout(() => {
      handleLoseFocus({ target: { value: newValue } });
    }, 1000);
  };

  return (
    <>
      <div className={classes.wrapper}>
        <Slider
          value={currentValue}
          onChange={handleChangeSlider}
          min={min}
          max={max}
          ref={forwardedRef}
          disabled={disabled}
          onBlur={handleLoseFocus}
        />

        <div className={classes.input}>
          <ButtonGroup
            className={classes.buttons}
            size="small"
            variant="outlined"
          >
            <Button size="small" onClick={handleIncrement}>
              <ArrowDropUpIcon fontSize="small" />
            </Button>
            <Button size="small" onClick={handleDecrement}>
              <ArrowDropDownIcon fontSize="small" />
            </Button>
          </ButtonGroup>
          <TextField
            variant="outlined"
            size="small"
            className="viewpoint"
            type="number"
            value={currentValue}
            onKeyDown={handleKeyDown}
            onBlur={handleLoseFocus}
            onChange={handleInputOnChange}
            disabled={disabled}
            inputProps={{
              ref: inputRef,
            }}
          />
          {unit && (
            <Typography minWidth={30} textAlign={'right'}>
              {unit}
            </Typography>
          )}
        </div>
      </div>
    </>
  );
};

export default InputRangeSlider;
