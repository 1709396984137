import { dbApiAddItem } from "./crudCalls";
import { hsApiGetNav, hsApiGetNavById } from "./nav";
import { formatZincToJson } from "../utils/formatZinc";
import { toast } from "react-toastify";
import { Equip, NavData } from "../types";
import { hsApiSendXml } from "./xml";
import { buildPanelName } from "../utils/panelsIndexBuilder";

type Props = {
  hsServerUrl: string;
  viewId: string;
  mappingId?: string;
  mappingContent?: string;
}

/**
 * Generates a view by retrieving data from the haystack server and creating panels.
 * 
 * @param {Props} props - The properties required to generate the view.
 * @param {string} props.hsServerUrl - The URL of the haystack server.
 * @param {string} props.viewId - The ID of the view.
 * @param {string} props.mappingId - The ID of the parent mapping.
 * @param {string} props.mappingContent - The content of the parent mapping.
 * @returns {Promise<void>} - A promise that resolves when the view generation is complete.
 */
export const generateView = async ({ hsServerUrl, viewId, mappingId = '', mappingContent }: Props): Promise<void> => {

  // we create all the panels
  const navData = await hsApiGetNav(hsServerUrl);
  const hsSiteData = formatZincToJson(navData).rows;
  const site = hsSiteData[0];
  // get site haystack nav data
  if (site.xmlId !== mappingId && mappingContent) {
    const formData = new FormData();
    //construct xml file
    const xmlFile = new Blob([mappingContent], {
      type: 'application/xml',
    });
    formData.append('xmlId', mappingId);
    formData.append('xmlContent', xmlFile);
    // send xml to haystack
    await hsApiSendXml(formData, hsServerUrl);
  }

  const siteNavData = await hsApiGetNavById(hsServerUrl, site.navId);

  const hsNavData: NavData[] = formatZincToJson(siteNavData).rows;

  // get all equipmements haystack nav data
  const allHsNavData = await Promise.all(
    hsNavData.map(async (equip: any) => {
      const equipNavData = await hsApiGetNavById(
        hsServerUrl,
        equip.navId
      );
      const pointData = formatZincToJson(equipNavData).rows;
      equip.points = pointData;
      return equip;
    })
  );

  Promise.all(
    allHsNavData.map(async (panel: Equip, index: number) => {
      return await dbApiAddItem('panels', {
        viewID: viewId,
        name: buildPanelName(panel.dis, index),
        points: JSON.stringify(panel.points),
        cleanPoints: JSON.stringify(panel.points),
      });
    })
  );

  toast.info('All Panels have been successfully created.');
}