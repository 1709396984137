import React from 'react';
import { Link } from 'react-router-dom';

import { useAppContext } from '../../context/AppContext';
import { UserDropdown } from './UserDropdown';
import { AppBar, Button, Toolbar } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const navItems = [
  {
    label: 'Projects',
    link: '/',
  },
  {
    label: 'Documentation',
    link: '/documentation',
  },
];

const useStyle = makeStyles()(() => ({
  link: {
    color: 'white',
    fontSize: 17,
    marginTop: 20,
  },
  header: {
    top: 0,
    height: 60,
  },
}));

export const Header = () => {
  const [, setState] = useAppContext();
  const { classes } = useStyle();

  const settingsClickHandle = () => {
    setState({ settingsModalVisible: true });
  };

  return (
    <AppBar position="fixed" className={classes.header}>
      <Toolbar>
        {navItems.map((navItem, index) => {
          return (
            <Link key={index} to={navItem.link}>
              <Button className={classes.link}>{navItem.label}</Button>
            </Link>
          );
        })}
        <Button className={classes.link} onClick={settingsClickHandle}>
          Settings
        </Button>
        <UserDropdown />
      </Toolbar>
    </AppBar>
  );
};
