import { MuiButton } from "./Button";
import * as MuiAppBar from "./AppBar";
import { MuiDrawer } from "./Drawer";
import * as MuiTextField from "./TextField";
import * as MuiTable from "./Table";
import * as MuiTabs from "./Tabs";
import * as MuiTypography from "./Typography";
import * as MuiCard from "./Card";
import * as MuiGrid from "./Grid";
import * as MuiTreeItem from "./TreeItem";
import * as MuiSwitch from "./Switch";

export const components = {
    MuiButton,
    ...MuiAppBar,
    MuiDrawer,
    ...MuiTextField,
    ...MuiTable,
    ...MuiTabs,
    ...MuiTypography,
    ...MuiCard,
    ...MuiGrid,
    ...MuiTreeItem,
    ...MuiSwitch
}
