import { palette } from "../palette";

export const MuiTabs = {
  defaultProps: {
    indicatorColor: "secondary"
  },
  styleOverrides: {
    root: {
      borderBottom: `1px solid ${palette.grey[450]}`,
    },
  },
}

export const MuiTab = {
  defaultProps: {
  },
  styleOverrides: {
    root: {
      textTransform: "capitalize",
      fontSize: "1rem",
      "&.Mui-selected": {
        color: palette.secondary.main,
      }
    }
  },
}
