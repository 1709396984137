import React from 'react';
import { AppProvider } from './context/AppContext';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { appTheme } from './styles/theme';
import { ThemeProvider } from '@mui/material/styles';
import { ProjectsListingPage } from './pages/ProjectsListing';
import { MappingsListingPage } from './pages/MappingsListing';
import { ViewsListingPage } from './pages/ViewsListing';
import { ViewDetailPage } from './pages/ViewDetail';
import { DocumentationPage } from './pages/Documentation';
import { Layout } from './components/layout/Layout';
import { AuthProvider } from './components/AuthProvider';
import { Bounce, ToastContainer } from 'react-toastify';
import { MappingAlert } from './components/modals/MappingAlert';

export const App = () => (
  <AppProvider>
    <AuthProvider>
      <ThemeProvider theme={appTheme}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          transition={Bounce}
        />
        <Router>
          <Layout>
            <Routes>
              <Route path="/" element={<ProjectsListingPage />} />
              <Route
                path="/project/:projectid"
                element={<MappingsListingPage />}
              />
              <Route
                path="/project/:projectid/mapping/:mappingid"
                element={
                  <MappingAlert>
                    <ViewsListingPage />
                  </MappingAlert>
                }
              />
              <Route
                path="/project/:projectid/mapping/:mappingid/view/:viewid"
                element={
                  <MappingAlert>
                    <ViewDetailPage />
                  </MappingAlert>
                }
              />
              <Route path="/documentation" element={<DocumentationPage />} />
            </Routes>
          </Layout>
        </Router>
        <ToastContainer />
      </ThemeProvider>
    </AuthProvider>
  </AppProvider>
);
