import { palette } from "../palette";

export const MuiTextField = {
    defaultProps: {
        fullWidth: true,
        size: "small"
    },
    styleOverrides: {
        root: {
            marginBottom: 10,
            "& .Mui-disabled": {
                backgroundColor: `${palette.grey[50]} !important;`
            },
            "&.viewpoint": {
                minWidth: 100,
                width: 100,
                marginBottom: 0,
                "& .Mui-disabled": {
                    backgroundColor: `white !important;`,
                },
                "& .MuiOutlinedInput-root": {
                    backgroundColor: "transparent",
                    fontWeight: "normal",
                    color: palette.primary.main,
                    maxHeight: 25,
                },
            }
        },
    },
}

export const MuiDateTimePickerToolbar = {
    styleOverrides: {
        root: {
            "& .MuiDateTimePickerToolbar-timeDigitsContainer *": {
                fontSize: "3rem"
            },
        },
    },
}

export const MuiInputBase = {
    defaultProps: {
    },
    styleOverrides: {
        root: {
            "& ::file-selector-button": {
                backgroundColor: palette.primary.main,
                color: "white",
                fontSize: 12,
                height: 24,
                paddingTop: 0,
                paddingBottom: 0,
                borderRadius: 5,
                marginLeft: -10
            },
            "&.file-input-uploader::before": {
                display: "none !important"
            },
            "&::after": {
                display: "none !important"
            },
            "&.MuiOutlinedInput-root": {
                backgroundColor: palette.blue[30],
                fontWeight: "bold",
                color: palette.primary.main,

            },
            "& input": {
                fontWeight: "bold",
                color: palette.primary.main,
            }
        },

    },
}

export const MuiSelect = {
    defaultProps: {
        size: "small"
    },
    styleOverrides: {
        root: {
            marginBottom: 10,
            backgroundColor: palette.blue[30],
        },
    },
}

export const MuiInputLabel = {
    defaultProps: {
    }
}
export const MuiFormLabel = {
    styleOverrides: {
        root: {
            fontSize: "0.875rem !important",
            zIndex: 2,
            color: palette.primary.main,
        },
        asterisk: {
            color: 'red',
            '&$error': {
                color: 'red'
            },
        }
    },
}