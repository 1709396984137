import React from 'react';
import { logout } from '@carrier/reactauthwrapper';
import LogoutSvg from '@mui/icons-material/Logout';
import { Button } from '@vscom/components';
import PersonIcon from '@mui/icons-material/Person';

import { useAppContext } from '../../context/AppContext';
import appConfig from '../../appConfig';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from 'tss-react/mui';
import { Typography } from '@mui/material';

const useStyle = makeStyles()(() => ({
  link: {
    color: 'white',
    fontSize: 17,
    marginTop: 20,
  },
  menu: {
    minWidth: 200,
  },
  menuItem: {
    minWidth: 200,
    displya: 'flex',
    justifyContent: 'space-between',
  },
}));

export const UserDropdown = () => {
  const { classes } = useStyle();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [state] = useAppContext();

  const handleLogout = () => logout(appConfig.login);

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        className={classes.link}
        style={{ marginLeft: 'auto' }}
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        endIcon={<PersonIcon />}
      >
        {state.userInfos && (
          <div>
            {state.userInfos.firstName} {state.userInfos.lastName}
          </div>
        )}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className={classes.menu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem className={classes.menuItem} onClick={handleLogout}>
          <Typography>Logout</Typography>
          <LogoutSvg />
        </MenuItem>
      </Menu>
    </>
  );
};
