
import { palette } from "../palette";

const HEIGHT = 16
const WIDTH = 45

export const MuiSwitch = {
  defaultProps: {},
  styleOverrides: {
    root: {
      width: WIDTH,
      height: HEIGHT + 4,
      padding: 0,
      display: 'flex',
      '&:active': {
        '& .MuiSwitch-thumb': {
          width: HEIGHT,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
          transform: `translateX(${9}px)`,
        },
      },
      '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
          transform: `translateX(${HEIGHT + HEIGHT / 2}px)`,
          color: '#fff',
          '& + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: palette.secondary.main,
          },
        },
      },
      '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: HEIGHT,
        height: HEIGHT,
        borderRadius: "50%",
      },
      '& .MuiSwitch-track': {
        borderRadius: (HEIGHT + 4) / 2,
        opacity: 1,
        backgroundColor:
          palette.grey[450],
        boxSizing: 'border-box',
      },
      "&.panel-switch": {
        '& .MuiSwitch-track': {
          backgroundColor: 'red !important',
        },
        '& .Mui-checked': {
          '& + .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: "green !important",
          },
        },
      },
    },
  },
}