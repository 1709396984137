export const MuiDrawer = {
    defaultProps: {
        anchor: "right",
        elevation: 3
    },
    styleOverrides: {
        root: {
            "& > .MuiPaper-root": {
                zIndex: 999,
                minWidth: 500,
                width: 500,
                padding: 20,
            }
        }
    }
}