import React from 'react';

import PlusSvg from '@mui/icons-material/Add';
import { IconButton, Paper, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

type Props = { label: string; onClick: (props: any) => void };

const useStyle = makeStyles()(() => ({
  paper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    minHeight: 200,
    height: '100%',
  },
}));

export const AddCard: React.FC<Props> = ({ label, onClick }) => {
  const { classes } = useStyle();
  return (
    <Paper className={classes.paper}>
      <IconButton onClick={onClick}>
        <PlusSvg fontSize="large" />
      </IconButton>
      <Typography>{label}</Typography>
    </Paper>
  );
};
