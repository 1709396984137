import { dbApiGetItem } from '../api/crudCalls'

const updateBreadcrumb = async (breadcrumbState: any, setStateFn: any, projectId?: string, mappingId?: string, viewId?: string) => {
    let copyBreadcrumbItems = [...breadcrumbState]
    copyBreadcrumbItems.length = 1

    if (projectId) {
        const projectData = await dbApiGetItem('projects', String(projectId))
        copyBreadcrumbItems.push({
            label: projectData.result.Name, link: `/project/${projectId}`
        })
    }

    if (mappingId) {
        const mappingData = await dbApiGetItem('mappings', mappingId)
        copyBreadcrumbItems.push({
            label: mappingData.result?.[0].Name, link: `/project/${projectId}/mapping/${mappingId}`
        })
    }

    if (viewId) {
        const viewData = await dbApiGetItem('views/view-detail', String(viewId))
        copyBreadcrumbItems.push({
            label: viewData.result?.[0].Name, link:
                `/project/${projectId}/mapping/${mappingId}/view/${viewId}`
        })
    }

    setStateFn({
        breadcrumbItems: copyBreadcrumbItems,
    })
}

export default updateBreadcrumb
