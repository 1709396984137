import { useEffect } from 'react';
import { useAppContext } from '../../context/AppContext';
import { Formik } from 'formik';

import { Input } from '../layout/Input';

import PlusSvg from '@mui/icons-material/Add';
import BinSvg from '@mui/icons-material/Delete';
import SaveSvg from '@mui/icons-material/Save';
import { Button } from '@vscom/components';
import { Drawer, Switch, Typography } from '@mui/material';
import { ModalHeader } from './Header';
import { FormWrapper } from './FormWrapper';
import { toast } from 'react-toastify';
import { useBoolean, useObjectState } from '@vscom/utils';
import { hsApiGetXml, hsApiSendXml } from '../../api/xml';
import {
  dbApiAddItem,
  dbApiDeleteItem,
  dbApiGetItem,
  dbApiUpdateItem,
} from '../../api/crudCalls';
import { generateTempId } from '../../utils/tempId';
import { useNavigate } from 'react-router-dom';
import { Download } from '@mui/icons-material';
import { Mapping } from '../../types';
import { generateView } from '../../api/generateView';

type Props = Mapping & {
  forceUpdate: () => void;
};

export const MappingModal: React.FC<Props> = ({
  ProjectID = '',
  forceUpdate,
  MappingID,
  Name,
  Description,
  MappingFile,
  MappingContent,
}) => {
  const [state, setState] = useAppContext();
  const navigate = useNavigate();
  const [mappingData, setMappingData] = useObjectState<any>({
    mappingName: Name,
    mappingDescription: Description,
    mappingFile: MappingContent,
    pic6hostip: '169.254.1.1',
    pic6port: '443',
    pic6path: '/haystack',
    pic6filter: 'device or deviceRef',
    pic6xmlId: generateTempId(),
  });
  const {
    value: automapping,
    toggle: toggleAutomapping,
    setFalse: setManual,
  } = useBoolean(false);
  const { value: showPic6options, setValue: setPic6options } =
    useBoolean(false);

  const handleToggleAuto = () => {
    toggleAutomapping();
    setPic6options(false);
  };

  useEffect(() => {
    setMappingData({
      mappingName: Name,
      mappingDescription: Description,
      mappingFile: MappingContent,
    });
  }, [Name, Description, MappingContent]);

  const hideModal = () => {
    setState({ mappingModalVisible: false });
    setManual();
    setState({ loading: false });
  };

  const refreshData = () => {
    hideModal();
    forceUpdate();
  };

  const getMappingAndSendXml = async (refresh = false) => {
    setState({ loading: true });
    try {
      const response = await dbApiGetItem('mappings', String(MappingID));

      const formData = new FormData();
      const xmlMapping = response.result[0].MappingContent;
      const xmlFile = new Blob([xmlMapping], {
        type: 'application/xml',
      });
      formData.append('xmlId', String(MappingID));
      formData.append('xmlContent', xmlFile);
      const result = await hsApiSendXml(formData, state.hsServerUrl);
      console.log(result);
      result.data && toast.warning(result.data);
      setState({ loading: false });
    } catch (error: any) {
      setState({ loading: false });
    }
  };

  const appendFormData = (values: any) => {
    const bodyFormData = new FormData();
    bodyFormData.append('projectID', String(ProjectID));
    bodyFormData.append('name', values.mappingName);
    bodyFormData.append('description', values.mappingDescription);
    bodyFormData.append('mappingFile', values.mappingFile);
    return bodyFormData;
  };

  const deleteMapping = async () => {
    setState({ loading: true });
    try {
      await dbApiDeleteItem(
        'mappings',
        String(MappingID),
        state.userInfos.userId
      );
      toast.info('Mapping has been deleted.');
      setState({ loading: false });
      setTimeout(refreshData, 300);
    } catch (error) {
      setState({ loading: false });
    }
  };

  const getXml = async (values: any) => {
    const allParams = {
      host: values.pic6hostip || '',
      port: values.pic6port || '',
      path: values.pic6path || '',
      filter: values.pic6filter || '',
      load: 'true',
      xmlId: values.pic6xmlId || '',
    };
    const params = new URLSearchParams(allParams);
    setState({ loading: true });
    const result = await hsApiGetXml(params, state.hsServerUrl);
    const boundary =
      '--' +
      result.headers['content-type'].split('; ')[1].replace('boundary=', '');
    const xmlData = result.data
      .split(boundary)[1]
      .replace(`Content-Disposition: form-data; name="xmlContent"`, '')
      .replace(`Content-Type: application/xml`, '');
    const xmlFile = new Blob([xmlData], {
      type: 'application/xml',
    });

    setState({
      loading: false,
      mappingModalVisible: false,
    });
    return xmlFile;
  };

  const saveAutoMapping = async (values: any, resetForm: any) => {
    try {
      setState({ loading: true });
      const xmlFile = await getXml(values);

      // first we save the mapping
      const bodyFormData = new FormData();
      bodyFormData.append('projectID', String(ProjectID));
      bodyFormData.append('name', String(values.mappingName));
      bodyFormData.append(
        'description',
        String(values.mappingDescription) || ''
      );
      bodyFormData.append('mappingFile', xmlFile);

      const mapping = await dbApiAddItem('mappings', bodyFormData);
      const mappingId = mapping.result;

      toast.info(`Mapping ${values.mappingName} has been successfully saved.`);

      // then we create the Default View
      const view = await dbApiAddItem('views', {
        mappingID: mappingId,
        name: 'Default',
        description: 'Generated view',
      });
      const viewId = view.result.id;
      toast.info('Default view has been successfully saved.');
      await generateView({
        hsServerUrl: state.hsServerUrl,
        viewId,
        mappingId: String(mappingId),
      });
      // and we redirect to the new view
      navigate(`/project/${ProjectID}/mapping/${mappingId}/view/${viewId}`);
      resetForm();
      setState({ loading: false });
    } catch (error) {
      setState({ loading: false });
    }
  };

  const addMapping = async (values: any, resetForm: any) => {
    const data = {
      mappingName: values.mappingName,
      mappingDescription: values.mappingDescription,
      mappingFile: values.mappingFile,
    };
    if (!values.mappingName) {
      toast.error('Please give the mapping a name');
      return;
    }
    if (automapping) {
      saveAutoMapping(values, resetForm);
    } else {
      try {
        const bodyFormData = appendFormData(data);
        setState({ loading: true });
        await dbApiAddItem('mappings', bodyFormData);

        toast.info('Mapping has been successfully created.');
        setState({ loading: false });
        resetForm();
        setTimeout(refreshData, 300);
      } catch (error) {
        setState({ loading: false });
      }
    }
  };

  const saveMapping = async (values: any) => {
    const bodyFormData = appendFormData(values);
    if (!!values.mappingFile && values.mappingFile.name !== MappingFile) {
      bodyFormData.set(
        'mappingFile',
        new File(
          [values.mappingFile],
          values.mappingFile.name || MappingFile || 'blob'
        )
      );
    }
    setState({ loading: true });

    try {
      await dbApiUpdateItem('mappings', bodyFormData, String(MappingID));

      toast.info('Mapping has been modified.');
      getMappingAndSendXml();
      setState({ loading: false });
      setTimeout(refreshData, 300);
    } catch (error) {
      setState({ loading: false });
    }
  };

  const downloadFile = async () => {
    try {
      const blob = new Blob([mappingData.mappingFile], {
        type: 'application/xml',
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${mappingData.mappingName}.xml`;
      a.click();
    } catch (error) {}
  };

  return (
    <Drawer anchor="right" open={state.mappingModalVisible} onClose={hideModal}>
      <ModalHeader
        title={!Name ? 'New Mapping' : `Edit ${Name}`}
        modalKey="mappingModalVisible"
        onClose={hideModal}
      />
      <Formik
        enableReinitialize={true}
        initialValues={mappingData}
        // validationSchema={mappingValidationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);

          Name ? saveMapping(values) : addMapping(values, resetForm);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => {
          return (
            <FormWrapper onSubmit={handleSubmit}>
              <div>
                <Input
                  id="mappingName"
                  name="mappingName"
                  label="Mapping name"
                  disabled={state.isReader}
                  value={values.mappingName}
                  onChange={handleChange}
                  required={true}
                  error={
                    errors.mappingName &&
                    touched.mappingName &&
                    errors.mappingName
                  }
                />

                <Input
                  id="mappingDescription"
                  name="mappingDescription"
                  label="Mapping description"
                  disabled={state.isReader}
                  value={values.mappingDescription}
                  onChange={handleChange}
                />
                <div style={{ display: 'flex', marginTop: 5, marginBottom: 8 }}>
                  <Typography style={{ marginRight: 5 }}>Manual</Typography>
                  <Switch
                    disabled={state.isReader || !!Name}
                    checked={automapping}
                    onChange={handleToggleAuto}
                  />
                  <Typography style={{ marginLeft: 5 }}>Auto</Typography>
                </div>
                {!automapping && (
                  <Input
                    id="mappingFile"
                    name="mappingFile"
                    label="Mapping file"
                    disabled={state.isReader}
                    onChange={(e) =>
                      setFieldValue('mappingFile', e.target.files[0])
                    }
                    type="file"
                    required={true}
                    error={
                      errors.mappingFile &&
                      touched.mappingFile &&
                      errors.mappingFile
                    }
                  />
                )}
                {automapping && (
                  <>
                    <Input
                      id="pic6hostip"
                      name="pic6hostip"
                      label="PIC 6 Host Ip Address *"
                      disabled={state.isReader}
                      value={values.pic6hostip}
                      onChange={handleChange}
                    />
                    <Button onClick={() => setPic6options(!showPic6options)}>
                      {!showPic6options ? 'Show more' : 'Hide'} options
                    </Button>
                    {showPic6options && (
                      <>
                        <Input
                          id="pic6port"
                          name="pic6port"
                          label="PIC 6 Host Port"
                          disabled={state.isReader}
                          value={values.pic6port}
                          onChange={handleChange}
                        />
                        <Input
                          id="pic6path"
                          name="pic6path"
                          label="PIC 6 Path"
                          disabled={state.isReader}
                          value={values.pic6path}
                          onChange={handleChange}
                        />
                        <Input
                          id="pic6filter"
                          name="pic6filter"
                          label="PIC 6 Filter"
                          disabled={state.isReader}
                          value={values.pic6filter}
                          onChange={handleChange}
                        />

                        <Input
                          id="pic6xmlId"
                          name="pic6xmlId"
                          label="PIC 6 XML ID"
                          disabled
                          value={values.pic6xmlId}
                        />
                      </>
                    )}

                    <Typography>
                      Automapping will fetch the mapping from the application
                      and send you to the generated view page.
                    </Typography>
                  </>
                )}
                {MappingFile && (
                  <Typography>Current mapping : {MappingFile}</Typography>
                )}

                {MappingFile && (
                  <Button
                    variant="contained"
                    color="info"
                    style={{ marginTop: 10 }}
                    startIcon={<Download />}
                    onClick={downloadFile}
                    disabled={state.loading}
                  >
                    Download File
                  </Button>
                )}
              </div>
              {!state.isReader && (
                <div>
                  {Name && (
                    <Button
                      fullWidth
                      variant="contained"
                      color="error"
                      style={{ marginTop: 10 }}
                      startIcon={<BinSvg />}
                      onClick={deleteMapping}
                      disabled={state.loading}
                    >
                      Delete mapping
                    </Button>
                  )}
                  <Button
                    fullWidth
                    carrier
                    style={{ marginTop: 10 }}
                    startIcon={Name ? <SaveSvg /> : <PlusSvg />}
                    type="submit"
                    disabled={state.loading}
                  >
                    {Name ? 'Save mapping' : 'Add mapping'}
                  </Button>
                </div>
              )}
            </FormWrapper>
          );
        }}
      </Formik>
    </Drawer>
  );
};
