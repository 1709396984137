import { useState } from "react";
import { useAppContext } from "../context/AppContext";
import { formatZincToJson } from "../utils/formatZinc";
import { callApi } from "./callApi";

export const hsApiGetNav = async (hsServerUrl: string) => {
  return await callApi({
    method: 'get',
    headers: { Accept: 'application/json' },
    url: `${hsServerUrl}/nav`
  })
}

export const hsApiGetNavById = async (hsServerUrl: string, navId: string) => {
  return await callApi({
    method: 'get',
    headers: { Accept: 'application/json' },
    url: `${hsServerUrl}/nav?navId=${navId}`
  })
}

export const useNavData = (mappingid: string) => {
  const [state, setState] = useAppContext()
  const [error, setError] = useState(false)

  const getNavData = async () => {
    const navData = await hsApiGetNav(state.hsServerUrl);
    const hsSiteData = formatZincToJson(navData).rows;
    const site = hsSiteData[0]

    if (site.xmlId !== mappingid) {
      // Mapping id is not the same as xmlId
      setError(true);
    } else {

      const navData = await hsApiGetNavById(state.hsServerUrl, site.navId);
      const hsNavData = formatZincToJson(navData).rows;

      const allHsNavData = await Promise.all(hsNavData.map(async (equip: any) => {
        const navData = await hsApiGetNavById(state.hsServerUrl, equip.navId);
        const pointData = formatZincToJson(navData).rows;
        equip.points = pointData;
        return equip
      }))

      setState({
        hsData: allHsNavData,
      });
      setError(false)
    }
  }

  return {
    getNavData,
    error
  }

}
