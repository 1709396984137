import { Dispatch, SetStateAction, useState } from 'react'

export function useObjectState<S>(initialValue: S | (() => S)): [S, Dispatch<SetStateAction<Partial<S>>>, Dispatch<SetStateAction<S>>] {
  const [state, setState] = useState(initialValue)

  const updateState = (keys: SetStateAction<Partial<S>>) => {
    setState((prevState: S) => ({
      ...prevState,
      ...keys
    }))
  }

  return [state, updateState, setState]
}