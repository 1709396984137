import React, { useRef } from 'react';
import { useAppContext } from '../../context/AppContext';
import {
  getConversion,
  isImperial,
  isMetric,
} from '../../utils/unitConversion';
import InputRangeSlider from '../layout/inputRangeSlider/InputRangeSlider';

export const NumberSlider = ({
  id,
  min,
  max,
  value,
  unit,
  disFmt,
  pointWriteCall,
  selectedPoint,
  disabled,
}: any) => {
  const [state] = useAppContext();

  const needConversion =
    (isImperial(selectedPoint.unit) && state.units === 'metric') ||
    (isMetric(selectedPoint.unit) && state.units === 'imperial');

  const selectedPointConverted: any =
    getConversion(selectedPoint.curVal, selectedPoint.unit, state.units) ??
    'NO UNIT';
  const sliderRef = useRef<any>(null);
  const actualMin = typeof min.val !== 'undefined' ? min.val : min;
  const actualMax = typeof max.val !== 'undefined' ? max.val : max;
  const actualValue = typeof value.val !== 'undefined' ? value.val : value;

  const onChangeComplete = (value = sliderRef.current.value) => {
    setTimeout(() => {
      if (sliderRef.current) {
        const sliderValue = needConversion
          ? Number(
              getConversion(
                value,
                selectedPointConverted.unit,
                state.units === 'imperial' ? 'metric' : 'imperial'
              )?.value
            )
          : value;

        const hsBody = `ver:"2.0"\nid,level,val,who\n@${id},1,${sliderValue},"WebUI"\n`;
        pointWriteCall(hsBody);
      }
    }, 250);
  };

  return (
    <InputRangeSlider
      id={id}
      min={actualMin}
      max={actualMax}
      value={actualValue}
      unit={unit}
      disabled={disabled}
      disFmt={disFmt}
      onChangeComplete={onChangeComplete}
      forwardedRef={sliderRef}
    />
  );
};
