import React from 'react';
import { useAppContext } from '../../context/AppContext';
import { IconButton, Typography } from '@mui/material';

import CloseSvg from '@mui/icons-material/Close';

type Props = {
  title: string;
  modalKey: string;
  onClose?: () => void;
};

export const ModalHeader: React.FC<Props> = ({ title, modalKey, onClose }) => {
  const [, setState] = useAppContext();

  const handleClose = () => {
    setState({ [modalKey]: false });
    if (onClose) {
      onClose();
    }
  };
  return (
    <Typography
      marginBottom={2}
      variant="h5"
      display="flex"
      justifyContent="space-between"
    >
      <span>{title}</span>
      <IconButton type="button" onClick={handleClose}>
        <CloseSvg />
      </IconButton>
    </Typography>
  );
};
