import React from 'react';
import { makeStyles } from 'tss-react/mui';

import Typography from '@mui/material/Typography';
import { CircularProgress, Paper } from '@mui/material';

const useStyle = makeStyles()(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: 100,
    height: 50,
    zIndex: 10,
    padding: 5,
  },
  progress: {},
  title: {
    marginLeft: 10,
    fontWeight: 'bold',
  },
}));

export const Loader: React.FC = () => {
  const { classes } = useStyle();
  return (
    <Paper elevation={2} className={classes.root}>
      <CircularProgress size={20} />
      <Typography className={classes.title}>Loading...</Typography>
    </Paper>
  );
};
