import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

export const hsApiSendXml = async (formData: FormData, hsServerUrl: string) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${hsServerUrl}/sendXml`,
      data: formData,
    });
    return response.data;
  } catch (error: any) {
    throw new Error(error.response.data);
  }
};

export const hsApiGetXml = async (
  params: URLSearchParams,
  hsServerUrl: string
) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${hsServerUrl}/getXml?${params.toString()}`,
    });
    return response;
  } catch (error: any) {
    if (error.message === 'Network Error') {
      toast.error('No response from vs_com.exe');
    } else if (error.response && error.response.status === 500) {
      const content = error.response.data
        ? JSON.stringify(error.response.data, null, 2)
        : 'No additional error data';
      toast.error(
        <>
          <b>
            {error.response.status} - {error.response.statusText}
          </b>
          <div>{content}</div>
        </>
      );
    } else {
      toast.error(error.message);
    }
    throw new Error(error);
  }
};
