/* eslint-disable array-callback-return */
import { useState, useEffect } from 'react';
import { useAppContext } from '../../context/AppContext';
import { Link } from 'react-router-dom';
import { TeamUserAvatar } from '../TeamUserAvatar';

import StarSvg from '@mui/icons-material/Star';
import EditSvg from '@mui/icons-material/Edit';
import HideSvg from '@mui/icons-material/VisibilityOff';
import CopySvg from '@mui/icons-material/ContentCopy';
import { AvatarGroup, IconButton, Paper, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { dbApiGetItem } from '../../api/crudCalls';

type Props = {
  id?: any;
  type?: any;
  link?: any;
  title?: any;
  description?: any;
  isHidden?: any;
  isBookmarked?: any;
  clickEdit?: any;
  toggleHiddenProject?: any;
  toggleBookmarkedProject?: any;
  copyProject?: any;
};

const useStyle = makeStyles()(() => ({
  paper: {
    minHeight: 200,
    padding: 10,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  avatars: {
    display: 'flex',
    justifyContent: 'start',
  },
  description: {
    flexGrow: 1,
    fontSize: 15,
  },
}));

export const Card: React.FC<Props> = ({
  id,
  type,
  link,
  title,
  description,
  isHidden,
  isBookmarked,
  clickEdit,
  toggleHiddenProject,
  toggleBookmarkedProject,
  copyProject,
}) => {
  const [state, setState] = useAppContext();
  const [users, setUsers] = useState([]);
  const [owner, setOnwer] = useState(null);
  const { classes } = useStyle();

  const fetchProjectTeam = async () => {
    setState({ loading: true });
    const projectData = await dbApiGetItem('projects/team', id);
    setState({ loading: false });
    if (!users.length && projectData.status === 'success') {
      const ownerId = projectData.result.owner;
      let projectUsers = [...state.appUsersList];
      const ownerInfo = projectUsers.find((el) => +el.userID === +ownerId);

      if (ownerInfo) {
        setOnwer(ownerInfo);
      }

      const otot = projectData.result.team.map((userTeam: any) => {
        const info = projectUsers.find((el) => +el.userID === +userTeam.UserID);
        if (info)
          return {
            email: info.email,
            firstName: info.firstName,
            lastName: info.lastName,
            userID: info.userID,
            Role: userTeam.Role.trim().toLowerCase(),
          };
      });
      setUsers(otot.filter(Boolean));
    }
  };

  useEffect(() => {
    if (type === 'project' && state.appUsersList.length) {
      setTimeout(() => {
        fetchProjectTeam();
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.appUsersList.length]);

  return (
    <Link to={link}>
      <Paper className={classes.paper}>
        <div className={classes.title}>
          <Typography variant="h6">{title}</Typography>
          <div>
            {toggleHiddenProject && (
              <IconButton
                style={{ color: isHidden ? 'green' : 'lightgrey' }}
                onClick={toggleHiddenProject}
              >
                <HideSvg />
              </IconButton>
            )}
            {toggleBookmarkedProject && (
              <IconButton
                style={{ color: isBookmarked ? 'gold' : 'lightgrey' }}
                onClick={toggleBookmarkedProject}
              >
                <StarSvg />
              </IconButton>
            )}
            {copyProject && (
              <IconButton onClick={copyProject}>
                <CopySvg />
              </IconButton>
            )}
            <IconButton onClick={clickEdit}>
              <EditSvg />
            </IconButton>
          </div>
        </div>
        <p className={classes.description}>{description}</p>
        <AvatarGroup className={classes.avatars}>
          {owner && <TeamUserAvatar user={owner} isOwner />}
          {users.length > 0 &&
            users.map((user: any, index) => (
              <TeamUserAvatar key={`${user.userID}-${index}`} user={user} />
            ))}
        </AvatarGroup>
      </Paper>
    </Link>
  );
};

export default Card;
