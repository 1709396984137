import {
  Button,
  List,
  ListItem,
  Menu,
  TextField,
  Typography,
} from '@mui/material';
import ChevronDownSvg from '@mui/icons-material/ExpandMore';
import { useState, useEffect, useRef, memo } from 'react';

export const BitFieldsDropdown = ({
  bitFields,
  currentValue,
  onBitValueChange,
  handleCloseDropdown,
  disabled,
}: any) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // Visibility
  const open = Boolean(anchorEl);
  // Bit description populated from haystack data
  const [bitFieldsArrayWithValue, setBitFieldsArrayWithValue] = useState<any[]>(
    []
  );

  useEffect(() => {
    const binaryValue = currentValue.toString(2).split('').reverse();
    const array = Object.entries(bitFields).map((bit) => {
      const index = parseInt(bit[0].substring(1));
      const value = binaryValue[index] === undefined ? 0 : binaryValue[index];

      return {
        index: index,
        label: bit[1],
        value: Number(value),
      };
    });
    setBitFieldsArrayWithValue(
      array.sort((a, b) => (a.index > b.index ? 1 : -1))
    );
  }, [currentValue, bitFields]);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: {}, reason: string) => {
    if (reason !== 'tabKeyDown') {
      setAnchorEl(null);
      handleCloseDropdown();
    }
  };

  // Bit change
  const handleBitInputChange = (bitValue: number, index: number) => {
    let bitsArray = currentValue.toString(2).split('').reverse();
    bitsArray[index] = bitValue;
    bitsArray = Array.from(bitsArray, (item) => Number(item) || 0);
    const value = parseInt(bitsArray.reverse().join(''), 2);
    // Propagate to upper component
    onBitValueChange(value);
  };

  const handleChangeValueKeyDown = (e: any, index: number) => {
    const keyCode = e.keyCode || e.which;
    // 48 = 0 / 49 = 1 / 96 = 0 (numpad) / 97 = 1 (numpad)
    const binary1KeyCodes = [49, 97];
    const binary0KeyCodes = [48, 96];
    let bitValue;
    if (binary0KeyCodes.includes(keyCode)) {
      bitValue = 0;
    } else if (binary1KeyCodes.includes(keyCode)) {
      bitValue = 1;
    } else {
      bitValue = Number(e.target.value) >= 1 ? 1 : 0;
    }
    const currentInput = document.getElementById(
      'bitField-' + index
    ) as HTMLInputElement;
    currentInput.value = bitValue.toString();
    handleBitInputChange(bitValue, index);
  };

  const onKeyDown = (e: React.KeyboardEvent, index?: number) => {
    switch (e.key) {
      case 'Tab':
        e.preventDefault();
        handleClose({}, 'tabKeyDown');
        if (index !== undefined) {
          const nextIndex = e.shiftKey ? index - 1 : index + 1;
          const nextInput = document.getElementById('bitField-' + nextIndex);
          if (nextInput) {
            nextInput.focus();
          }
          handleChangeValueKeyDown(e, index);
        }
        return;
      case 'Enter':
        handleClose({}, 'enterKeyDown');
        // Trigger bit value change
        if (index !== undefined) {
          handleChangeValueKeyDown(e, index);
        }
        return;

      default:
        break;
    }
  };

  return (
    <>
      <Button
        id="BitFieldsDropdown-button"
        disabled={disabled}
        onClick={handleOpenMenu}
      >
        Binary
        <ChevronDownSvg />
      </Button>
      <Menu
        id="BitFieldsDropdown"
        aria-labelledby="BitFieldsDropdown-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onKeyDown={onKeyDown}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <List>
          {bitFieldsArrayWithValue.map((bitField) => {
            return (
              <SingleBitInput
                key={bitField.index}
                handleChange={handleChangeValueKeyDown}
                bitField={bitField}
                onKeyDown={onKeyDown}
              />
            );
          })}
        </List>
      </Menu>
    </>
  );
};

const SingleBitInput = memo(
  ({
    handleChange,
    bitField,
    onKeyDown,
  }: {
    handleChange: (...props: any) => void;
    bitField: any;
    onKeyDown: (event: any, index: number) => void;
  }) => {
    const ref = useRef<any>(null);
    useEffect(() => {
      if (Number(bitField.value) !== Number(ref.current.value)) {
        ref.current.value = Number(bitField.value);
      }
    }, [bitField.value]);

    const handleKeyPress = (e: any) => {
      onKeyDown(e, bitField.index);
      e.preventDefault();
      handleChange(e, bitField.index);
    };

    return (
      <ListItem>
        <Typography variant="body2" style={{ marginRight: 8 }}>
          <b>Bit {bitField.index}: </b>
          {bitField.label}
        </Typography>
        <TextField
          id={'bitField-' + bitField.index}
          className="viewpoint no-arrow-number"
          variant="outlined"
          size="small"
          type="number"
          defaultValue={bitField.value}
          onKeyDown={handleKeyPress}
          inputProps={{
            ref,
          }}
        />
      </ListItem>
    );
  }
);
