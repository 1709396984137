import { useObjectState } from '@vscom/utils';
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react';
import { Equip } from '../types';

export type AppContextType = {
  loading: boolean;
  userInfos: any;
  appUsersList: any[];
  hsData: Equip[] | null;
  hsServerUrl: string;
  units: any;
  comFault: any;
  settingsModalVisible: boolean;
  projectModalVisible: boolean;
  mappingModalVisible: boolean;
  viewModalVisible: boolean;
  sortModalVisible: boolean;
  panelModalVisible: boolean;
  panelModalWatchId: any;
  closedPanelWatchId: any;
  isReader: boolean;
  isContributor: boolean;
  viewPanels: any[];
  breadcrumbItems: {
    label: string;
    link: string;
  }[];
};

const DEFAULT_SERVER_URL = 'http://localhost:8085';

const initState = {
  loading: false,
  userInfos: null,
  appUsersList: [],
  hsData: null,
  hsServerUrl:
    typeof window !== 'undefined'
      ? sessionStorage.getItem('serverUrl') || DEFAULT_SERVER_URL
      : DEFAULT_SERVER_URL,
  units:
    typeof window !== 'undefined' ? sessionStorage.getItem('units') : 'metric',
  comFault: 'enabled',
  settingsModalVisible: false,
  projectModalVisible: false,
  mappingModalVisible: false,
  viewModalVisible: false,
  panelModalVisible: false,
  sortModalVisible: false,
  panelModalWatchId: null,
  closedPanelWatchId: null,
  isReader: false,
  isContributor: true,
  viewPanels: [],
  breadcrumbItems: [
    {
      label: 'Projects',
      link: '/',
    },
  ],
};

export const AppContext = createContext<
  [
    state: AppContextType,
    setState: Dispatch<SetStateAction<Partial<AppContextType>>>,
  ]
>([initState, () => {}]);

export const AppProvider = (props: any) => {
  const [state, updateState] = useObjectState<AppContextType>(initState);

  // useEffect(() => console.log(state), [JSON.stringify(state)]);

  return (
    <AppContext.Provider value={[state, updateState]}>
      {props.children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
