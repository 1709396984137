import { useEffect, useRef, useState } from 'react';
// Filter key stroke to be only 0-1 inputs
import { TextField, Typography } from '@mui/material';
import { useBoolean } from '@vscom/utils';
import { makeStyles } from 'tss-react/mui';

const useStyle = makeStyles()(() => ({
  wrapper: {
    display: 'flex',
    width: '100%',
    maxWidth: 110,
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginLeft: 30,
  },
  title: {
    marginRight: 10,
    width: 30,
  },
}));

export const HexView = ({ id, value, pointWriteCall, disabled }: any) => {
  const { classes } = useStyle();
  const inputRef = useRef<any>(null);

  // Initialize currentValue in hexadecimal
  const [currentValue, setCurrentValue] = useState(value.toString(16));

  const {
    value: changedValue,
    setFalse: resetChanged,
    setTrue: setChanged,
  } = useBoolean(false);

  useEffect(() => {
    if (!changedValue) {
      setCurrentValue(value.toString(16));
      inputRef.current.value = value.toString(16);
    }
  }, [value]);

  const handleOnBlur = ({ target: { value } }: any) => {
    const decimalValue = parseInt(value, 16);
    setCurrentValue(decimalValue.toString(16));
    resetChanged();
    setTimeout(() => {
      const hsBody = `ver:"2.0"\nid,level,val,who\n@${id},1,${decimalValue},"WebUI"\n`;
      pointWriteCall(hsBody);
    }, 250);
  };

  const handleInputOnChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setChanged();
    const decimalValue = parseInt(value, 16);
    setCurrentValue(decimalValue.toString(16));
    inputRef.current.value = decimalValue.toString(16);
  };

  return (
    <div className={classes.wrapper}>
      <Typography className={classes.title}>Hex</Typography>
      <TextField
        inputProps={{
          ref: inputRef,
        }}
        variant="outlined"
        size="small"
        className="viewpoint"
        value={currentValue}
        type="text"
        disabled={disabled}
        onBlur={handleOnBlur}
        onChange={handleInputOnChange}
      />
    </div>
  );
};
