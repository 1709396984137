import { useEffect, DependencyList } from "react";

/**
 * Custom hook for creating an interval.
 * @param callback The function to be executed at each interval.
 * @param delay The delay in milliseconds.
 * @param deps The dependencies to be passed to the useEffect hook.
 */
export const useInterval = (callback: () => void, delay: number = 1000, deps: DependencyList) => {
  useEffect(() => {
    let id = setInterval(callback, delay);
    return () => clearInterval(id);
  }, deps);
};