/* eslint-disable array-callback-return */
import React, { useMemo } from 'react';
import { Autocomplete, InputLabel, TextField, Typography } from '@mui/material';

type Props = {
  id?: string;
  name?: string;
  label?: string;
  users: any[];
  required?: string;
  onChange: (props: any) => void;
  isDisabled?: boolean;
  error?: string;
  isMulti?: boolean;
  selectedUsers: any[];
};

const userInfosToLabel = (user: any) => {
  return `${user.firstName} ${user.lastName} (${user.email?.toLowerCase()})`;
};

export const TeamSelect: React.FC<Props> = ({
  label,
  users,
  selectedUsers,
  required,
  onChange,
  isDisabled,
  isMulti = true,
  error,
}) => {
  const handleChange = (event: any, value: any[]) => {
    onChange(value);
  };

  const ids = useMemo(
    () => selectedUsers.map((user: any) => user?.userID) || [],
    [selectedUsers]
  );

  const selectedValues = React.useMemo(() => {
    if (isMulti) {
      return users.filter((v) => ids.includes(v.userID));
    }
    return users.find((v) => ids.includes(v.userID)) || {};
  }, [users, ids, isMulti]);

  return (
    <>
      <InputLabel>
        <span dangerouslySetInnerHTML={{ __html: label as string }} />
        {required && <span>*</span>}
      </InputLabel>
      <Autocomplete
        multiple={isMulti}
        disabled={isDisabled}
        value={selectedValues}
        fullWidth
        options={users}
        onChange={handleChange}
        getOptionLabel={(option) => userInfosToLabel(option)}
        renderInput={(params) => <TextField {...params} />}
      />
      {error && <Typography color="error">{error}</Typography>}
    </>
  );
};
