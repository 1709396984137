import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

type Props = {
  warnings: string;
  setWarnings: (warnings: string | null) => void;
};
export const WarningModal: React.FC<Props> = ({ warnings, setWarnings }) => {
  return (
    <Dialog
      open={!!warnings}
      maxWidth="sm"
      fullWidth
      onClose={() => setWarnings(null)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Errors/Warnings received from vs_com.exe
      </DialogTitle>
      <DialogContent>
        {warnings
          ?.split('\n')
          ?.map((line: string) => <Typography key={line}>{line}</Typography>)}
      </DialogContent>
    </Dialog>
  );
};
