import axios, { AxiosError, AxiosRequestConfig } from "axios"
import { toast } from "react-toastify";

export const callApi = async (options: AxiosRequestConfig) => {
  try {
    const response = await axios(options);
    return response.data
  } catch (error: any) {
    console.error("callApi error", error);
    toast.error(error.message)
    throw new AxiosError(error)
  }
}