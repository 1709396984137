// const API_URL = 'https://vscom.hvac-engineering.eu/'
const API_URL = window.location.origin

const isDev = process.env.REACT_APP_ENV === "dev";

const appUiUrl =
    isDev ? 'https://dev.vscom.hvac-engineering.eu'
        : "https://vscom.hvac-engineering.eu"

const appConfig = {
    api: {
        dbApiUrl:
            window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'
                ? `http://localhost:8080/api`
                : `${API_URL}/api`,

        auth: {
            Authorization: `Bearer ${localStorage.getItem('msal.idtoken')}`,
        },
    },
    login: {
        uiUrl: appUiUrl,
        apiUrl: 'https://api.carrierappshub.com/',
        postLogoutRedirectUrl: 'http://localhost:3000',
    },
}

export default appConfig
