import React from 'react';
import { Switch } from '@mui/material';

export const StatusSwitch = ({ id, status, pointWriteCall, disabled }: any) => {
  const actualStatus = typeof status.val !== 'undefined' ? status.val : status;

  const onChange = (e: any) => {
    const switchValue = e.target.checked;
    const hsBody = `ver:"2.0"\nid,level,val,who\n@${id},1,${switchValue ? 'T' : 'F'},"WebUI"\n`;

    pointWriteCall(hsBody);
  };

  return (
    <Switch
      className="panel-switch"
      checked={!!actualStatus}
      onChange={onChange}
      disabled={disabled}
    />
  );
};
