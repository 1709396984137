import { useState, useEffect } from 'react';
import { useAppContext } from '../../context/AppContext';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Input from '../layout/Input';

import { Button } from '@vscom/components';
import PlusSvg from '@mui/icons-material/Add';
import BinSvg from '@mui/icons-material/Delete';
import SaveSvg from '@mui/icons-material/Save';
import { Drawer, Switch, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { ModalHeader } from './Header';
import { FormWrapper } from './FormWrapper';
import {
  dbApiAddItem,
  dbApiDeleteItem,
  dbApiUpdateItem,
} from '../../api/crudCalls';
import { Mapping, View } from '../../types';
import { generateView } from '../../api/generateView';

const viewValidationSchema = Yup.object().shape({
  Name: Yup.string().required(`Please enter a view name`),
});

type Props = {
  view: View | null;
  mapping: Mapping | null;
  forceUpdate: () => void;
};

export const ViewModal: React.FC<Props> = ({ view, mapping, forceUpdate }) => {
  const [state, setState] = useAppContext();
  const [viewData, setViewData] = useState<View | null>(view);
  const [autoView, setAutoView] = useState<boolean>(true);

  useEffect(() => {
    setViewData(view);
  }, [view]);

  const hideModal = () => {
    setState({ viewModalVisible: false });
  };

  const refreshData = () => {
    hideModal();
    forceUpdate();
  };

  const deleteView = async () => {
    setState({ loading: true });
    if (!view) return;
    try {
      await dbApiDeleteItem('views', view.ViewID, state.userInfos.userId);
      toast.info('View has been deleted.');
      setState({ loading: false });
      setTimeout(refreshData, 300);
    } catch (error) {
      setState({ loading: false });
    }
  };

  const addView = async (values: any, resetForm: any) => {
    setState({ loading: true });
    try {
      const view = await dbApiAddItem('views', {
        mappingID: mapping?.MappingID,
        name: values.Name,
        description: values.Description,
      });
      toast.info('View has been successfully created.');
      if (autoView) {
        // then we create the generated View
        const viewId = view.result.id;
        await generateView({
          hsServerUrl: state.hsServerUrl,
          viewId,
          mappingId: String(mapping?.MappingID),
          mappingContent: mapping?.MappingContent,
        });
      }
      setState({ loading: false });
      resetForm();
      setTimeout(refreshData, 300);
    } catch (error) {
      setState({ loading: false });
    }
  };

  const saveView = async (values: any) => {
    setState({ loading: true });
    if (!view) return;
    try {
      await dbApiUpdateItem(
        'views',
        {
          name: values.Name,
          description: values.Description,
        },
        view.ViewID
      );
      toast.info('View has been successfully created.');
      setState({ loading: false });
      setTimeout(refreshData, 300);
    } catch (error) {
      setState({ loading: false });
    }
  };

  const handleAutoViewChange = (_: any, newValue: boolean) =>
    setAutoView(newValue);

  return (
    <Drawer anchor="right" open={state.viewModalVisible}>
      <ModalHeader
        title={!view?.Name ? 'New View' : `Edit ${view.Name}`}
        modalKey="viewModalVisible"
      />
      <Formik
        enableReinitialize={true}
        initialValues={viewData || { Name: '', Description: '' }}
        validationSchema={viewValidationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          view?.Name ? saveView(values) : addView(values, resetForm);
        }}
      >
        {({ values, errors, touched, handleChange, handleSubmit }) => {
          return (
            <FormWrapper onSubmit={handleSubmit}>
              <div>
                <Input
                  id="Name"
                  name="Name"
                  label="View name"
                  disabled={state.isReader}
                  value={values.Name}
                  onChange={handleChange}
                  required={true}
                  error={errors.Name && touched.Name && errors.Name}
                />
                <Input
                  id="Description"
                  name="Description"
                  label="View description"
                  disabled={state.isReader}
                  value={values.Description}
                  onChange={handleChange}
                />
                {!view?.Name && (
                  <div
                    style={{ display: 'flex', marginTop: 5, marginBottom: 8 }}
                  >
                    <Typography style={{ marginRight: 5 }}>Manual</Typography>
                    <Switch
                      checked={autoView}
                      onChange={handleAutoViewChange}
                    />
                    <Typography style={{ marginLeft: 5 }}>Auto</Typography>
                  </div>
                )}
              </div>
              {!state.isReader && (
                <div>
                  {view?.Name && (
                    <Button
                      fullWidth
                      color="error"
                      variant="contained"
                      style={{ marginTop: 10 }}
                      startIcon={<BinSvg />}
                      onClick={deleteView}
                      disabled={state.loading}
                    >
                      Delete view
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    fullWidth
                    carrier
                    style={{ marginTop: 10 }}
                    startIcon={view?.Name ? <SaveSvg /> : <PlusSvg />}
                    type="submit"
                    disabled={state.loading}
                  >
                    {view?.Name ? 'Save view' : 'Add view'}
                  </Button>
                </div>
              )}
            </FormWrapper>
          );
        }}
      </Formik>
    </Drawer>
  );
};
