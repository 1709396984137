import { breakpoints } from "./breakpoints";
import { pxToRem } from "./util";

export const typography: any = {
    fontFamily: 'Roboto, sans-serif',
    h2: {
        fontSize: pxToRem(20),
        fontWeight: 600,
        lineHeight: '23px',
        paddingBottom: pxToRem(2),
        [`@media (min-width: ${breakpoints.values.sm}px)`]: {
            paddingBottom: '24px',
        },
    },
    h3: {
        fontSize: pxToRem(16),
        fontWeight: 600,
        paddingBottom: 0,
        [`@media (min-width: ${breakpoints.values.sm}px)`]: {
            paddingBottom: 0,
        },
    },
    body1: {
        fontSize: pxToRem(14),
        lineHeight: '1.3',
        [`@media (min-width: ${breakpoints.values.sm}px)`]: {
            fontSize: pxToRem(16),
        },
    },
    subtitle1: {
        fontSize: pxToRem(14),
        lineHeight: '16px',
        fontWeight: 'bold',
    },
    subtitleBold: {
        fontSize: pxToRem(14),
        lineHeight: '16px',
        fontWeight: 600,
    },
    caption: {
        fontSize: pxToRem(12),
        lineHeight: '14px',
    },
    text: {
        fontSize: pxToRem(16),
        fontWeight: 600,
    },
    subtitleBold2: {
        height: pxToRem(16),
        letterSpacing: pxToRem(1),
        fontWeight: 600,
    },
}