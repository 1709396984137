import { useEffect, useState } from 'react';
import { useAppContext } from '../context/AppContext';
import { useParams } from 'react-router-dom';
import updateBreadcrumb from '../utils/updateBreadcrumb';

import { Breadcrumb } from '../components/layout/Breadcrumb';
import { AddCard } from '../components/cards/AddCard';
import { MappingModal } from '../components/modals/MappingModal';
import { Card } from '../components/cards/Card';
import { Grid, Typography } from '@mui/material';
import { dbApiGetItem } from '../api/crudCalls';
import { Mapping } from '../types';

export const MappingsListingPage = () => {
  let { projectid } = useParams();
  const [state, setState] = useAppContext();
  const [mappingsData, setMappingsData] = useState<any>();
  const [mappingModalData, setMappingModalData] = useState<Mapping>();

  const fetchProject = async () => {
    const response = await dbApiGetItem('projects/team', String(projectid));
    setState({
      isContributor: true,
      isReader: false,
    });
    response.result.team.forEach((user: any) => {
      if (
        user.UserID === state?.userInfos?.userId &&
        user.Role.trim() === 'reader'
      )
        setState({
          isContributor: false,
          isReader: true,
        });
    });
  };

  const fetchMappings = async () => {
    setState({ loading: true });
    const response = await dbApiGetItem('mappings/project', String(projectid));
    setMappingsData(response.result);
    setState({ loading: false });
  };

  useEffect(() => {
    fetchProject();
    fetchMappings();
    updateBreadcrumb(state.breadcrumbItems, setState, projectid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectid]);

  const addMapping = () => {
    setState({
      mappingModalVisible: true,
    });
    setMappingModalData({
      Name: '',
      Description: '',
      MappingFile: '',
      ProjectID: Number(projectid),
    });
  };
  const clickEdit = (event: any, mapping: any) => {
    event.preventDefault();
    setState({
      mappingModalVisible: true,
    });
    setMappingModalData(mapping);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Breadcrumb breadcrumbItems={state.breadcrumbItems} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">Mappings</Typography>
        </Grid>
        <Grid item xs={6} md={4} lg={3}>
          <AddCard label="Add mapping" onClick={addMapping} />
        </Grid>
        {mappingsData &&
          mappingsData.map((mapping: any, index: number) => {
            return (
              <Grid
                item
                key={mappingsData + mapping.MappingID + index}
                xs={6}
                md={4}
                lg={3}
              >
                <Card
                  link={`/project/${projectid}/mapping/${mapping.MappingID}`}
                  title={mapping.Name}
                  description={mapping.Description}
                  clickEdit={(event: any) => {
                    console.log(mapping);
                    return clickEdit(event, mapping);
                  }}
                />
              </Grid>
            );
          })}
      </Grid>
      <MappingModal {...mappingModalData} forceUpdate={fetchMappings} />
    </>
  );
};
