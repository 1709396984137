import mappingConversion from './mappingConversion.json'
import * as units from 'haystack-units'
import { HUnit } from 'haystack-core'

const allUnits: any = units

/**
 * Converts a string to camel case.
 * @param s - The input string
 * @returns The input string converted to camel case
 */
export const toCamelCase = (s: string): string => {
    return s.replace(/([-_][a-z])/gi, ($1: string) => {
        return $1.toUpperCase().replace('-', '').replace('_', '')
    })
}

/**
 * Check if the given unit name is a metric unit.
 * @param unitName - The unit name to be checked.
 * @returns True if the unit is a metric unit, false otherwise.
 */
export const isMetric = (unitName: string): boolean => {
    const metric: boolean = !!mappingConversion.find(({ metric }: { metric: string }) => metric === unitName);
    return metric;
}

/**
 * Check if the given hackstackUnitName is an imperial unit.
 * @param hackstackUnitName The name of the hackstack unit to check.
 * @returns Whether the hackstack unit is an imperial unit.
 */
export const isImperial = (hackstackUnitName: string): boolean => {
    const imperial = mappingConversion.find(({ imperial }: { imperial: string }) => imperial === hackstackUnitName)
    return !!imperial
}

/**
 * Get the label for the specified haystack unit name.
 * @param hackstackUnitName - The name of the haystack unit
 * @returns The label of the haystack unit, or an empty string if not found
 */
export const getLabel = (hackstackUnitName: string): string => {
    try {
        const label: HUnit = new HUnit(allUnits[toCamelCase(hackstackUnitName)])
        return label?.symbol ?? ''
    } catch {
        console.warn('getLabel from HUnit: ', hackstackUnitName, " don't exist on standard haystack unit.")
        return hackstackUnitName
    }
}

type ConversionReturn = { value: number, unit: string }

/**
 * Converts a value from one unit to another based on the current metric system.
 * @param hackstackUnitValue - The value to be converted.
 * @param hackstackUnitName - The unit of the value to be converted.
 * @param currentMetric - The current metric system, either 'imperial' or 'metric'.
 * @returns An object with the converted value and the new unit, or the original value and unit if conversion fails.
 */
export const getConversion = (
    hackstackUnitValue: number,
    hackstackUnitName: string,
    currentMetric: 'imperial' | 'metric'
): ConversionReturn | undefined => {
    try {
        if (!hackstackUnitName || isNaN(hackstackUnitValue))
            return { value: hackstackUnitValue, unit: hackstackUnitName }

        let otherMetric: string | undefined = undefined
        let convertedValue: any = undefined

        if (currentMetric === 'imperial') {
            const unitConversion = mappingConversion.find(({ metric }) => metric === hackstackUnitName)

            otherMetric = unitConversion?.imperial ?? undefined
        }

        if (currentMetric === 'metric') {
            const unitConversion = mappingConversion.find(({ imperial }) => imperial === hackstackUnitName)
            otherMetric = unitConversion?.metric ?? undefined
        }

        if (otherMetric) {
            const haystackUnit = new HUnit(allUnits[toCamelCase(hackstackUnitName)])
            convertedValue = +haystackUnit.convertTo(hackstackUnitValue, allUnits[toCamelCase(otherMetric)])
        }

        if (otherMetric && !isNaN(convertedValue)) {
            return {
                value: +convertedValue?.toFixed(2),
                unit: otherMetric,
            }
        }

        return { value: hackstackUnitValue, unit: hackstackUnitName }
    } catch (error) {
        console.error(error)
    }
}
