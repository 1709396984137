import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import updateBreadcrumb from '../utils/updateBreadcrumb';

import { Breadcrumb } from '../components/layout/Breadcrumb';
import { AddCard } from '../components/cards/AddCard';
import { ViewModal } from '../components/modals/ViewModal';
import Card from '../components/cards/Card';
import { useAppContext } from '../context/AppContext';
import { dbApiGetItem } from '../api/crudCalls';
import { Mapping, View } from '../types';

export const ViewsListingPage = () => {
  let { projectid, mappingid } = useParams();
  const [state, setState] = useAppContext();
  const [mappingData, setMappingData] = useState<Mapping | null>(null);
  const [viewsData, setViewsData] = useState<View[]>();
  const [selectedView, setSelectedView] = useState<View | null>(null);

  const fetchViews = async () => {
    setState({ loading: true });
    const mapping = await dbApiGetItem('mappings', String(mappingid));
    const response = await dbApiGetItem('views', String(mappingid));
    const views = typeof response.result === 'object' ? response.result : [];
    setMappingData(mapping.result?.[0]);
    setViewsData(views);
    setState({ loading: false });
  };

  useEffect(() => {
    fetchViews();
    updateBreadcrumb(state.breadcrumbItems, setState, projectid, mappingid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addView = () => {
    setState({
      viewModalVisible: true,
    });

    setSelectedView(null);
  };

  const clickEdit = (event: any, view: View) => {
    event.preventDefault();

    setState({
      viewModalVisible: true,
    });
    setSelectedView(view);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Breadcrumb breadcrumbItems={state.breadcrumbItems} />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h4">Views</Typography>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <AddCard label="Add view" onClick={addView} />
        </Grid>
        {viewsData &&
          viewsData.map((view: View, index: number) => (
            <Grid item key={'viewsData' + view.ViewID} xs={6} md={4} lg={3}>
              <Card
                link={`/project/${projectid}/mapping/${mappingid}/view/${view.ViewID}`}
                title={view.Name}
                description={view.Description}
                clickEdit={(event: any) => clickEdit(event, view)}
              />
            </Grid>
          ))}
      </Grid>
      <ViewModal
        view={selectedView}
        mapping={mappingData}
        forceUpdate={() => fetchViews()}
      />
    </>
  );
};
