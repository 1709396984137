
export const palette: any = {
    primary: {
        main: '#152C73',
    },
    secondary: {
        main: '#1891F6',
    },
    grey: {
        50: '#f0f0f4',
        150: '#dddde1',
        400: '#E5E7ED',
        450: '#BAC0D0',
        550: '#4A596A',
        750: '#617080',
        900: '#333333',
        main: '#A7AFC3',
        dark: '#747A88',
    },
    blue: {
        20: '#bddefc',
        30: '#e6f2fe',
        40: '#d1e9fd',
        50: '#d8e4f4',
        60: '#cfe6fd',
    },
    red: {
        20: '#fff4f4',
        30: '#ffeaea',
        40: '#ffdfdf',
        50: '#eddae1',
        main: '#FF2A2A',
    },
    orange: {
        100: '#E9A52B',
        900: '#EB5623',
    },
    yellow: {
        main: '#E9A52B',
    },
    text: {
        primary: '#333333',
        secondary: '#A7AFC3',
        grey: '#a7abb6',
        disabled: ""
    },
    warning: {
        main: '#FF2A2A',
        light: "",
        dark: "",
        contrastText: ""
    },
    background: {
        white: 'white',
        default: "white",
        paper: "white"
    },
}

export const carrierGradient = `radial-gradient(
    52.63% 300.89% at 49.31% 251.79%,
    ${palette.secondary.main} 0%,
    ${palette.primary.main} 100%
)`