import React, { ReactElement, useEffect } from 'react';
import { ReactAuthWrapper, UserInfo } from '@carrier/reactauthwrapper';

// import { Loader } from './Loader';
import appConfig from '../appConfig';
import { getUsersFromPermission } from '../api/fetchUserApi';
import { useAppContext } from '../context/AppContext';

export const AuthProvider: React.FC<{
  children: ReactElement | ReactElement[];
}> = ({ children }) => {
  const [, setState] = useAppContext();

  const getPermissions = async (userInfos: UserInfo) => {
    setState({ loading: true });
    // const userInfos = await getUserProfile();
    const appUsersList = await getUsersFromPermission();
    setState({
      userInfos,
      appUsersList,
      loading: false,
    });
  };

  return (
    <ReactAuthWrapper
      appConfig={appConfig.login}
      getPermissions={getPermissions}
    >
      {children}
    </ReactAuthWrapper>
  );
};
