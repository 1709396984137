const isKeyCodeBinary = (keyCode: any) => {
    // 48 = 0 / 49 = 1 / 96 = 0 (numpad) / 97 = 1 (numpad)
    const binaryKeyCodes = [48, 49, 96, 97]
    // 8 = backspace / 46 = delete
    const deleteKeyCodes = [8, 46]
    // 17 = ctrl / 65 = a
    const ctrlAKeyCodes = [17, 65]
    // 9 = tab
    const tabKeyCode = 9;
    // 13 = enter
    const enterKeyCode = 13;

    if (binaryKeyCodes.includes(keyCode) || deleteKeyCodes.includes(keyCode) || ctrlAKeyCodes.includes(keyCode) || keyCode === tabKeyCode || keyCode === enterKeyCode) {
        return true;
    }

    return false
}

export default isKeyCodeBinary
