import { palette } from "../palette";

export const MuiButton = {
    styleOverrides: {
        root: {
            /* Use MyButton uppercase property to get uppercase characters */
            textTransform: 'capitalize',
            fontWeight: "normal",
            background: ({ color = "primary" }: { color: 'primary' | 'secondary' }) => palette[color].main,
            boxShadow: 'none',
            '&:hover': {
                boxShadow: 'none',
            },
        },
    },
}