import { breakpoints } from "../breakpoints";
import { carrierGradient } from "../palette";

export const MuiAppBar = {
    styleOverrides: {
        root: {
            height: 40,
            background: carrierGradient,
            // boxShadow: 'none'
        },
    },
}

export const MuiToolbar = {
    styleOverrides: {
        root: {
            height: 40,
            minHeight: 40,
            [`@media screen and (min-width: ${breakpoints.values.sm}px)`]: {
                minHeight: 40,
            },
        },
    },
}