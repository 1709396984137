import { alpha } from '@mui/material/styles';
import { treeItemClasses } from '@mui/x-tree-view';
import { palette } from '../palette';
import { typography } from '../typography';

export const MuiTreeItem = {
  defaultProps: {},
  styleOverrides: {
    root: {
      color: palette.primary.main,
      [`& .${treeItemClasses.content}`]: {
        color: palette.primary.main,
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
        paddingRight: 3,
        fontWeight: typography.fontWeightMedium,
        '&.Mui-expanded': {
          fontWeight: typography.fontWeightRegular,
        },
        '&:hover': {
          backgroundColor: palette.action?.hover,
        },
        [`& .${treeItemClasses.label}`]: {
          fontWeight: 'inherit',
          color: 'inherit',
        },
      },
      [`& .${treeItemClasses.iconContainer}`]: {
        '& .close': {
          opacity: 0.3,
        },
      },
      [`& .${treeItemClasses.group}`]: {
        marginLeft: 15,
        borderLeft: `1px dashed ${alpha(palette.text.primary, 0.4)}`,
      },
    }
  },
}