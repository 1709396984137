import { callApi } from "./callApi";

const headers = {
  'Content-Type': 'text/plain',
}

export const hsApiWatchUnsub = async (body: string, hsServerUrl: string) => await callApi({
  method: 'post',
  data: body,
  url: `${hsServerUrl}/watchUnsub`,
  headers
})

export const hsApiWatchPoll = async (body: string, hsServerUrl: string) => await callApi({
  method: 'post',
  data: body,
  url: `${hsServerUrl}/watchPoll`,
  headers
})

export const hsApiWatchSub = async (body: string, hsServerUrl: string) => await callApi({
  method: 'post',
  data: body,
  url: `${hsServerUrl}/watchSub`,
  headers
})

export const hsApiWatchList = async (hsServerUrl: string) => await callApi({
  method: 'get',
  url: `${hsServerUrl}/watchList`,
  headers
})

export const hsApiPointWrite = async (body: string, hsServerUrl: string) => await callApi({
  method: 'post',
  data: body,
  url: `${hsServerUrl}/pointWrite`,
  headers
})
