export const DocumentationPage = () => {
  return (
    <>
      <section className="mt-12 space-y-8">
        <h1 className="text-xl font-medium">Project</h1>
        <p>
          A projet represent a collection of views, configurations and presets.
          It is usually associated with a business project like a new product
          introduction, a new option introduction or a sustaining project.
        </p>
      </section>
      <section className="space-y-8">
        <h1 className="text-xl font-medium">View</h1>
        <p>
          A view represent a collection of points that a developer wants to see
          or interact with during his code testing session.
        </p>
      </section>
    </>
  );
};
