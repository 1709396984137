import { Typography } from '@mui/material';
import React from 'react';

export const NumberDisplay = ({ value, unit, disFmt }: any) => {
  const actualValue = typeof value.val !== 'undefined' ? value.val : value;

  const formatValue = (value: any, disFmt: any) => {
    if (disFmt === 'Hex') {
      return '0x' + value.toString(16);
    }

    if (disFmt === 'Bin') {
      return value.toString(2).replace(/\d{4}(?=.)/g, '$& ');
    }
    return value;
  };

  return (
    <Typography>
      {formatValue(actualValue, disFmt)}
      {!!unit ? ` ${unit}` : ''}
    </Typography>
  );
};
