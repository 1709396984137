import { InputLabel } from '@mui/material';
import { TextField } from 'formik-mui';
import { Field } from 'formik';
import React from 'react';

type Props = {
  id: string;
  type?: string;
  name?: string;
  label?: string;
  placeholder?: string;
  value?: string | number;
  required?: boolean;
  disabled?: boolean;
  onChange?: (props: any) => void;
  onBlur?: (props: any) => void;
  error?: any;
  defaultValue?: any;
};

export const Input: React.FC<Props> = ({
  id,
  type,
  name,
  label,
  placeholder,
  value,
  required,
  disabled,
  onChange,
  onBlur,
  error,
  defaultValue,
}) => {
  return (
    <>
      <InputLabel>
        {label}
        {required && <span>*</span>}
      </InputLabel>
      <Field
        id={id}
        type={type}
        component={TextField}
        name={name}
        variant="outlined"
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
      />
    </>
  );
};

export default Input;
