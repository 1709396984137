import React from 'react';
import { Avatar } from '@mui/material';

export const StatusReadOnly = ({ status }: any) => {
  const actualStatus = typeof status.val !== 'undefined' ? status.val : status;
  const backgroundColor = actualStatus ? 'green' : 'red';
  return (
    <Avatar
      style={{
        height: 25,
        width: 25,
        fontSize: 12,
        marginLeft: 8,
        backgroundColor,
      }}
    >
      {actualStatus ? 'T' : 'F'}
    </Avatar>
  );
};
