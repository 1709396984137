import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@vscom/components';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { dbApiGetItem } from '../../api/crudCalls';
import { hsApiSendXml } from '../../api/xml';
import { hsApiGetNav, hsApiGetNavById } from '../../api/nav';
import { useAppContext } from '../../context/AppContext';
import { formatZincToJson } from '../../utils/formatZinc';
import { WarningModal } from './WarningModal';

type Props = {
  children: React.ReactNode;
};
export const MappingAlert: React.FC<Props> = ({ children }) => {
  const { projectid, mappingid } = useParams();
  const [open, setOpen] = useState<boolean>(false);
  const [initiated, setInitiated] = useState<boolean>(false);
  const [warnings, setWarnings] = useState<any>();
  const [state, setState] = useAppContext();

  const handleClose = () => {
    setOpen(false);
  };

  const getNavData = async () => {
    const navData = await hsApiGetNav(state.hsServerUrl);
    const hsSiteData = formatZincToJson(navData).rows;
    const site = hsSiteData[0];
    if (site.xmlId === mappingid) {
      // get site haystack nav data
      const siteNavData = await hsApiGetNavById(state.hsServerUrl, site.navId);
      const hsNavData = formatZincToJson(siteNavData).rows;

      // get all equipmements haystack nav data
      const allHsNavData = await Promise.all(
        hsNavData.map(async (equip: any) => {
          const equipNavData = await hsApiGetNavById(
            state.hsServerUrl,
            equip.navId
          );
          const pointData = formatZincToJson(equipNavData).rows;
          equip.points = pointData;
          return equip;
        })
      );

      setState({
        hsData: allHsNavData,
      });
      setInitiated(true);
      setOpen(false);
      return allHsNavData;
    } else {
      setOpen(true);
    }
  };

  useEffect(() => {
    getNavData();
  }, [initiated]);

  const getMappingAndSendXml = async () => {
    setState({ loading: true });
    try {
      // get mapping from API
      const response = await dbApiGetItem('mappings', String(mappingid));
      const formData = new FormData();
      const xmlMapping = response.result[0].MappingContent;

      //construct xml file
      const xmlFile = new Blob([xmlMapping], {
        type: 'application/xml',
      });
      formData.append('xmlId', String(mappingid));
      formData.append('xmlContent', xmlFile);

      // send xml to haystack
      const sendXmlResponse = await hsApiSendXml(formData, state.hsServerUrl);
      setInitiated(true);
      sendXmlResponse &&
        toast.warning('Received warning(s). Click for details', {
          onClick: () => {
            setWarnings(sendXmlResponse);
          },
        });
      setState({ loading: false });
    } catch (error: any) {
      setState({ loading: false });
      if (error) {
        toast.error('Received error(s). Click for details', {
          onClick: () => setWarnings(error.message),
        });
      }
    }
  };
  if (initiated) {
    return (
      <>
        {children}
        <WarningModal warnings={warnings} setWarnings={setWarnings} />
      </>
    );
  }
  return (
    <>
      <WarningModal warnings={warnings} setWarnings={setWarnings} />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Mapping is missing</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This mapping needs to be sent to the server in order to use it.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Link to={`/project/${projectid}`}>
            <Button fullWidth>Go back to mappings</Button>
          </Link>
          <Button carrier onClick={getMappingAndSendXml}>
            Send new mapping
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
