import { useEffect, useState, useRef, useMemo } from 'react';
import { useAppContext } from '../context/AppContext';

import { InputSearch } from '../components/layout/InputSearch';
import { AddCard } from '../components/cards/AddCard';
import { ProjectModal } from '../components/modals/ProjectModal';
import Card from '../components/cards/Card';
import {
  FormControlLabel,
  Checkbox,
  FormGroup,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import {
  dbApiCopyProject,
  dbApiGetProjects,
  dbApiGetProjectsTeam,
  dbApiToggleBookmarkedProject,
  dbApiToggleHiddenProject,
} from '../api/projects';

type Filter = {
  hidden: boolean;
  bookmarked: boolean;
  owner: boolean;
  contributor: boolean;
  reader: boolean;
};

const useStyle = makeStyles()(() => ({
  topBar: {
    padding: 10,
    display: 'flex',
    justifyContent: 'space-around',
  },
}));

export const ProjectsListingPage = () => {
  const [state, setState] = useAppContext();
  const [projectsData, setProjectsData] = useState<any[]>([]);
  const [activeSearch, setActiveSearch] = useState(false);
  const [bookmarkedProjects, setBookmarkedProjects] = useState<any[]>([]);
  const [nothiddenProjects, setnotHiddenProjects] = useState<any[]>([]);
  const [searchedProjectsData, setSearchedProjectsData] = useState<any[]>([]);
  const [projectModalId, setProjectModalId] = useState<string>();
  const [projectModalName, setProjectModalName] = useState<string>('');
  const [projectModalDescr, setProjectModalDescr] = useState<string>('');
  const [projectModalTeam, setProjectModalTeam] = useState<any>();
  const [projectModalOwner, setProjectModalOwner] = useState<string>('');
  const projectsSearchInput = useRef<HTMLInputElement>();
  const [filter, setFilter] = useState<Filter>({
    hidden: false,
    bookmarked: false,
    owner: true,
    contributor: true,
    reader: true,
  });

  const { classes } = useStyle();

  const toggleHiddenProject = async (id: string, e: any) => {
    e.preventDefault();
    setState({ loading: true });
    try {
      await dbApiToggleHiddenProject(state.userInfos.userId, id);
      fetchProjects();
      setState({ loading: false });
    } catch (error) {
      setState({ loading: false });
    }
  };

  const handleCopyProject = async (id: string, e: any) => {
    e.preventDefault();
    setState({ loading: true });
    try {
      await dbApiCopyProject(state.userInfos.userId, id);
      fetchProjects();
      setState({ loading: false });
    } catch (error) {
      setState({ loading: false });
    }
  };

  const toggleBookmarkedProject = async (id: string, e: any) => {
    e.preventDefault();
    setState({ loading: true });
    try {
      await dbApiToggleBookmarkedProject(state.userInfos.userId, id);
      fetchProjects();
      setState({ loading: false });
    } catch (error) {
      setState({ loading: false });
    }
  };

  const toggleCheckbox = (event: any) => {
    const {
      target: { name, checked },
    } = event;
    let prevFilter = { ...filter };
    if (name === 'bookmarked' && checked)
      prevFilter = { ...prevFilter, hidden: false };
    setFilter({ ...prevFilter, [name]: checked });
  };

  const fetchProjects = async () => {
    setState({ loading: true });
    // Look for the bookmarked projects
    const bookmarked = await dbApiGetProjects(
      state.userInfos.userId,
      Object.keys(filter)
        .map((key) =>
          key === 'bookmarked'
            ? key + '=true'
            : key + '=' + filter[key as keyof Filter]
        )
        .join('&')
    );

    typeof bookmarked.result === 'object'
      ? setBookmarkedProjects(bookmarked.result)
      : setBookmarkedProjects([]);

    // Look for the projects not hidden
    const notHidden = await dbApiGetProjects(
      state.userInfos.userId,
      Object.keys(filter)
        .map((key) =>
          key === 'hidden'
            ? key + '=false'
            : key + '=' + filter[key as keyof Filter]
        )
        .join('&')
    );
    typeof notHidden.result === 'object'
      ? setnotHiddenProjects(notHidden.result)
      : setnotHiddenProjects([]);

    // Look for the filtered projects
    const filtered = await dbApiGetProjects(
      state.userInfos.userId,
      Object.keys(filter)
        .map((key) => key + '=' + filter[key as keyof Filter])
        .join('&')
    );

    setProjectsData(filtered.result || []);
    setState({ loading: false });
  };

  useEffect(() => {
    if (state.userInfos) {
      fetchProjects();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.userInfos, filter]);

  const addProject = () => {
    setState({
      projectModalVisible: true,
    });

    setProjectModalId('');
    setProjectModalName('');
    setProjectModalDescr('');
    setProjectModalTeam('');
    setProjectModalOwner('');
  };

  const clickEdit = async (
    event: any,
    id: string,
    name: string,
    descr: string
  ) => {
    event.preventDefault();

    const response = await dbApiGetProjectsTeam(id);
    if (response.status === 'success') {
      let projectUsers = [...state.appUsersList];

      const ownerId = response.result.owner;
      const ownerInfo = projectUsers.find((el) => +el.userID === +ownerId);
      if (ownerInfo) {
        setProjectModalOwner(ownerInfo);
      }

      setState({
        isContributor: true,
        isReader: false,
      });
      response.result.team.forEach((user: any) => {
        if (
          user.UserID === state?.userInfos?.userId &&
          user.Role.trim() === 'reader'
        )
          setState({
            isContributor: false,
            isReader: true,
          });
      });

      const cardTeam = response.result.team.map((userTeam: any) => {
        const info = projectUsers.find((el) => +el.userID === +userTeam.UserID);
        if (info)
          return {
            email: info.email,
            firstName: info.firstName,
            lastName: info.lastName,
            userID: info.userID,
            Role: userTeam.Role.trim().toLowerCase(),
          };
        return null;
      });
      setProjectModalTeam(cardTeam.filter(Boolean));
    }

    setProjectModalId(id);
    setProjectModalName(name);
    setProjectModalDescr(descr);

    setState({
      projectModalVisible: true,
    });
  };

  const projectsSearch = () => {
    const searchTerm = projectsSearchInput?.current?.value || '';
    let searchedProjectsData = [...projectsData];
    searchedProjectsData = searchedProjectsData.filter((project: any) =>
      project.Name.toLowerCase().includes(searchTerm)
    );
    setSearchedProjectsData(searchedProjectsData);
    searchTerm.length ? setActiveSearch(true) : setActiveSearch(false);
  };

  const projectsToDisplay = useMemo(() => {
    if (activeSearch) {
      return searchedProjectsData;
    }
    return projectsData;
  }, [activeSearch, searchedProjectsData, projectsData]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={classes.topBar}>
            <Typography style={{ lineHeight: 1.7 }} variant="h5">
              Projects
            </Typography>
            <InputSearch
              forwardedRef={projectsSearchInput}
              placeholder="Search"
              onChange={projectsSearch}
            />

            <FormGroup
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {Object.entries(filter).map(([key, value]) => (
                <FormControlLabel
                  key={key}
                  control={
                    <Checkbox
                      checked={value}
                      name={key}
                      onChange={toggleCheckbox}
                    />
                  }
                  label={key}
                />
              ))}
            </FormGroup>
          </Paper>
        </Grid>
        <Grid item xs={6} md={4} lg={3}>
          <AddCard label="Add new project" onClick={addProject} />
        </Grid>

        {projectsToDisplay.map((project: any, index) => {
          return (
            <Grid
              item
              xs={6}
              md={4}
              lg={3}
              key={'projectsData' + project.ProjectID + index}
            >
              <ProjectCard
                project={project}
                nothiddenProjects={nothiddenProjects}
                bookmarkedProjects={bookmarkedProjects}
                clickEdit={clickEdit}
                toggleHiddenProject={toggleHiddenProject}
                toggleBookmarkedProject={toggleBookmarkedProject}
                handleCopyProject={handleCopyProject}
              />
            </Grid>
          );
        })}
      </Grid>

      <ProjectModal
        id={Number(projectModalId)}
        name={projectModalName}
        descr={projectModalDescr}
        team={projectModalTeam}
        owner={projectModalOwner}
        forceUpdate={fetchProjects}
      />
    </>
  );
};

type Project = {
  ProjectID: string;
  Name: string;
  Description: string;
};

type ProjectCardProps = {
  project: Project;
  nothiddenProjects: Project[];
  bookmarkedProjects: Project[];
  clickEdit: (
    event: React.MouseEvent,
    projectId: string,
    name: string,
    description: string
  ) => void;
  toggleHiddenProject: (projectId: string, event: React.MouseEvent) => void;
  toggleBookmarkedProject: (projectId: string, event: React.MouseEvent) => void;
  handleCopyProject: (projectId: string, event: React.MouseEvent) => void;
};

const ProjectCard: React.FC<ProjectCardProps> = ({
  project,
  nothiddenProjects,
  bookmarkedProjects,
  clickEdit,
  toggleHiddenProject,
  toggleBookmarkedProject,
  handleCopyProject,
}: ProjectCardProps) => (
  <Card
    type="project"
    id={project.ProjectID}
    link={`/project/${project.ProjectID}`}
    title={project.Name}
    description={project.Description}
    isHidden={!nothiddenProjects.some((p) => p.ProjectID === project.ProjectID)}
    isBookmarked={bookmarkedProjects.some(
      (p) => p.ProjectID === project.ProjectID
    )}
    clickEdit={(event: any) =>
      clickEdit(event, project.ProjectID, project.Name, project.Description)
    }
    toggleHiddenProject={(e: any) => toggleHiddenProject(project.ProjectID, e)}
    toggleBookmarkedProject={(e: any) =>
      toggleBookmarkedProject(project.ProjectID, e)
    }
    copyProject={(e: any) => handleCopyProject(project.ProjectID, e)}
  />
);
