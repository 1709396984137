import { useState, useEffect } from 'react';
import { useAppContext } from '../../context/AppContext';

import { ButtonSwitch } from '../layout/ButtonSwitch';

import SaveSvg from '@mui/icons-material/Save';
import { useBoolean } from '@vscom/utils';
import { Button } from '@vscom/components';
import { Drawer, InputLabel, TextField } from '@mui/material';
import { ModalHeader } from './Header';
import { FormWrapper } from './FormWrapper';
import { toast } from 'react-toastify';

const unitsItems = [
  {
    id: 'metric',
    label: 'Metric',
  },
  {
    id: 'imperial',
    label: 'Imperial',
  },
];

export const SettingsModal = () => {
  const [state, setState] = useAppContext();
  const { value: submitting, toggle: toggleSubmitting } = useBoolean(false);
  const [settingsData, setSettingsData] = useState({
    serverUrl: state.hsServerUrl ?? 'http://localhost:8085',
    units: state.units ?? 'metric',
  });

  useEffect(() => {
    if (state.hsServerUrl) {
      setState({ settingsModalVisible: false });
    } else {
      setState({ settingsModalVisible: true });
    }
  }, [state.hsServerUrl]);

  const saveSettings = () => {
    const values = {
      serverUrl: settingsData.serverUrl,
      units: settingsData.units,
    };
    toggleSubmitting();
    const serverUrl = values.serverUrl.endsWith('/')
      ? values.serverUrl.slice(0, -1)
      : values.serverUrl;
    sessionStorage.setItem('serverUrl', serverUrl);
    sessionStorage.setItem('units', values.units);

    toast.info('Settings have been modified. Page will be refreshed.');
    toggleSubmitting();
    setState({
      hsServerUrl: serverUrl,
      settingsModalVisible: false,
    });

    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const changeUnits = (e: any) => {
    setSettingsData({
      ...settingsData,
      units: e.target.value,
    });
  };
  const changeServerUrl = (e: any) => {
    setSettingsData({
      ...settingsData,
      serverUrl: e.target.value,
    });
  };

  return (
    <Drawer anchor="right" open={state.settingsModalVisible}>
      <ModalHeader title="Settings" modalKey="settingsModalVisible" />
      <FormWrapper onSubmit={saveSettings}>
        <div>
          <ButtonSwitch
            label="Units"
            items={unitsItems}
            value={settingsData.units}
            handleOnChange={changeUnits}
          />

          <InputLabel>Haystack server url *</InputLabel>
          <TextField
            id={'serverUrl'}
            variant="outlined"
            value={settingsData.serverUrl}
            defaultValue={settingsData.serverUrl || 'http://localhost:8085'}
            onChange={changeServerUrl}
          />
        </div>
        <Button
          fullWidth
          carrier
          onClick={saveSettings}
          startIcon={<SaveSvg />}
          type="submit"
          disabled={submitting}
        >
          Save settings
        </Button>
      </FormWrapper>
    </Drawer>
  );
};
