import { Avatar, useTheme } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

export const TeamUserAvatar: React.FC<{ user: any; isOwner?: boolean }> = ({
  user,
  isOwner,
}) => {
  const theme = useTheme();
  const isContributor = user.Role === 'contributor';
  const bgcolor = isOwner
    ? theme.palette.primary.main
    : isContributor
      ? theme.palette.secondary.main
      : theme.palette.secondary.light;
  return (
    <Tooltip title={`${user.firstName} ${user.lastName}`}>
      <Avatar
        sx={{
          width: 30,
          height: 30,
          fontSize: 30 / 2,
          bgcolor,
          opacity: isContributor || isOwner ? 1 : 0.6,
        }}
      >
        {user.firstName.substring(0, 1)}
        {user.lastName.substring(0, 1)}
      </Avatar>
    </Tooltip>
  );
};

export default TeamUserAvatar;
