import { pxToRem } from "./util"

export const rem = {
    9: pxToRem(9),
    11: pxToRem(11),
    16: pxToRem(16),
    80: pxToRem(80),
    109: pxToRem(109),
    125: pxToRem(125),
}
export const alignPosition = {
    center: 'center',
}

export const common = {
    overFlowEllipsis: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    displayFlexRow: {
        display: 'flex',
        flexDirection: 'row',
    },
    displayFlexColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
}

