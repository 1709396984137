import { palette } from '../palette';

export const MuiTable = {
  styleOverrides: {
    root: {
      '&#datamanager': {
        borderSpacing: '0 8px',
        borderCollapse: 'separate',

      },
    },
  },
};

export const MuiTableBody = {
  styleOverrides: {
    root: {},
  },
};
export const MuiTableHead = {
  styleOverrides: {
    root: {
      '& th': {
        color: palette.grey[750],
      },
    },
  },
};
export const MuiTableRow = {
  styleOverrides: {
    root: {
      '& .MuiTableCell-root:first-of-type': {
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
      },
      '& .MuiTableCell-root:last-child': {
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5
      },
      '&:not(.MuiTableRow-head)': {
        backgroundColor: palette.background.white,
      },
      '&:hover': {
        // backgroundColor: palette.grey[50]
      },
    },
  },
};
export const MuiTableCell = {
  styleOverrides: {
    root: {
      borderStyle: 'none solid solid none',
      padding: 10,
      lineHeight: 0.8,
      border: 'none',
      '& a': {
        color: palette.secondary.main,
        fontWeight: 'bold',
      },
    },
  },
};
