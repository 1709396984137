import appConfig from "../appConfig"
import { callApi } from "./callApi";

export const dbApiGetProjectsTeam = async (id: number | string) => await callApi({
  method: 'get',
  url: `${appConfig.api.dbApiUrl}/projects/team/${id}`,
  headers: appConfig.api.auth,
})

export const dbApiGetProjects = async (userId: string, queryString: string) => await callApi({
  method: 'get',
  url: `${appConfig.api.dbApiUrl}/user-projects/${userId}?${queryString}`,
  headers: appConfig.api.auth,
})

export const dbApiToggleHiddenProject = async (userId: string, id: number | string) => await callApi({
  method: 'post',
  url: `${appConfig.api.dbApiUrl}/flag-projects/user/${userId}/project/${id}/hidden`,
  headers: appConfig.api.auth,
})

export const dbApiCopyProject = async (userId: string, id: number | string) => await callApi({
  method: 'post',
  url: `${appConfig.api.dbApiUrl}/projects/user/${userId}/project/${id}/copy`,
  headers: appConfig.api.auth,
})

export const dbApiToggleBookmarkedProject = async (userId: string, id: number | string) => await callApi({
  method: 'post',
  url: `${appConfig.api.dbApiUrl}/flag-projects/user/${userId}/project/${id}/bookmarked`,
  headers: appConfig.api.auth,
})
