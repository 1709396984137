/* eslint-disable array-callback-return */
import {
  useContext,
  useState,
  useEffect,
  createContext,
  useRef,
  useMemo,
} from 'react';
import { useAppContext } from '../../context/AppContext';

import PlusSvg from '@mui/icons-material/Add';
import BinSvg from '@mui/icons-material/Delete';
import SaveSvg from '@mui/icons-material/Save';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Drawer,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  Typography,
  TextField,
} from '@mui/material';
import { Button } from '@vscom/components';
import { ModalHeader } from './Header';
import { ArrowDropDown } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';
import { useBoolean } from '@vscom/utils';
import { toast } from 'react-toastify';
import {
  dbApiAddItem,
  dbApiDeleteItem,
  dbApiUpdateItem,
} from '../../api/crudCalls';
import { hsApiWatchUnsub } from '../../api/watch';
import { Equip, Panel, Point, SelectedPoint } from '../../types';
import { buildPanelName, getPanelName } from '../../utils/panelsIndexBuilder';

const PointExInput = '/assets/points/input.png';
const PointExSlider = '/assets/points/slider.png';
const PointExStatus = '/assets/points/status.png';
const PointExSwitch = '/assets/points/switch.png';

const useStyle = makeStyles()(() => ({
  accordion: {
    padding: '0px !important',
    border: 'none',
    position: 'relative',
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
    '&.Mui-expanded': {
      margin: 0,
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: 0,
    },
    '& .MuiAccordionSummary-root': {
      minHeight: '35px !important',
    },
  },
  buttons: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
  },
  summary: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
}));

export const PanelModelContext = createContext<{
  selectPoint: (point: Point, currentPointValue: any) => void;
  toggleSelectAll: any;
  selectedPoints: SelectedPoint[];
}>({
  selectPoint: () => {},
  toggleSelectAll: null,
  selectedPoints: [],
});

type Props = {
  parentViewId?: string;
  panel?: Panel;
  panelsData: Panel[];
  forceUpdate: () => void;
};

export const PanelModal: React.FC<Props> = ({
  parentViewId,
  panel,
  panelsData,
  forceUpdate,
}) => {
  const points = panel ? JSON.parse(panel.Points) : [];
  const cleanPoints = panel ? JSON.parse(panel.CleanPoints) : [];
  const [state, setState] = useAppContext();
  // const [selectedPoints, setSelectedPoints] = useState<
  //   { id: string; selectedPoints: Point[] }[]
  // >([]);
  const [selectedPoints, setSelectedPoints] = useState<SelectedPoint[]>([]);
  const selectPointsCountUpdate = selectedPoints?.reduce(
    (acc: any, curr: any) => acc + curr.selectedPoints?.length,
    0
  );
  const inputRef = useRef<HTMLInputElement>();
  const selectPointsCount: number = cleanPoints.length || 0;

  useEffect(() => {
    getHsPoints();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.hsData, panel]);

  const getHsPoints = () => {
    if (state.hsData) {
      const selectedPointsArray = state.hsData.map((equip: Equip) => {
        return {
          id: equip.id?.val,
          selectedPoints: points.filter((point: Point) => {
            return point.equipRef?.val === equip.id?.val;
          }),
        };
      });

      setSelectedPoints(selectedPointsArray);
    }
  };

  const hideModal = () => {
    setState({ panelModalVisible: false });
  };

  const refreshData = () => {
    hideModal();
    forceUpdate();
  };

  const deletePanel = async () => {
    setState({ loading: true });
    watchUnsubCall();
    if (!panel?.PanelID) return toast.error('Panel ID is missing');
    try {
      await dbApiDeleteItem('panels', panel?.PanelID, state.userInfos.userId);
      toast.info('Panel has been deleted.');
      setState({ loading: false });
      refreshData();
    } catch (error) {
      setState({ loading: false });
    }
  };

  const pointsWithoutEquips = (points: SelectedPoint[]) => {
    let selectedPointsWithoutEquips: Point[] = [];

    points.forEach((point: any) => {
      if (point.selectedPoints.length) {
        point.selectedPoints.forEach((selectedPoint: Point) => {
          selectedPointsWithoutEquips = [
            ...selectedPointsWithoutEquips,
            selectedPoint,
          ];
        });
      }
    });

    return selectedPointsWithoutEquips;
  };

  const watchUnsubCall = async () => {
    const unsubBody = `ver:"2.0" watchId:"${state.panelModalWatchId}" close:M\nid\n`;
    await hsApiWatchUnsub(unsubBody, state.hsServerUrl);
    setState({ closedPanelWatchId: state.panelModalWatchId });
  };

  const addPanel = async (e: any) => {
    e.preventDefault();
    if (!inputRef?.current?.value) {
      return toast.error('Please enter panel name');
    }
    setState({ loading: true });
    try {
      await dbApiAddItem('panels', {
        viewID: parentViewId,
        name: buildPanelName(inputRef.current.value, panelsData.length + 1),
        points: JSON.stringify(selectedPoints),
        cleanPoints: JSON.stringify(pointsWithoutEquips(selectedPoints)),
      });
      toast.info('Panel has been successfully created.');
      setState({ loading: false });
      if (inputRef.current) inputRef.current.value = '';
      setSelectedPoints([]);
      getHsPoints();
      refreshData();
    } catch (error) {
      setState({ loading: false });
    }
  };

  const savePanel = async (e: any) => {
    e.preventDefault();
    if (!inputRef?.current?.value) {
      return toast.error('Please enter panel name');
    }
    setState({ loading: true });
    if (!panel?.PanelID) return toast.error('Panel ID is missing');

    try {
      const change = selectPointsCount === selectPointsCountUpdate;
      const data = {
        name: inputRef?.current?.value,
        points: JSON.stringify(
          selectedPoints.reduce(
            (acc: Point[], curr: SelectedPoint) => [
              ...acc,
              ...curr.selectedPoints,
            ],
            []
          )
        ),
        cleanPoints: change
          ? JSON.stringify(cleanPoints)
          : JSON.stringify(pointsWithoutEquips(selectedPoints)),
      };
      await dbApiUpdateItem('panels', data, panel.PanelID);
      toast.info('Panel has been modified.');
      setState({ loading: false });
      watchUnsubCall();
      refreshData();
    } catch (error) {
      setState({ loading: false });
    }
  };

  const toggleSelectAll = (equip: Equip, toggleValue: any) => {
    let selectedPointsCopy = [...selectedPoints];

    selectedPointsCopy.filter((currentEquip) => {
      if (currentEquip.id === equip.id.val) {
        if (toggleValue) {
          currentEquip.selectedPoints = equip.points;
        } else {
          currentEquip.selectedPoints = [];
        }
      }
    });

    setSelectedPoints(selectedPointsCopy);
  };

  const selectPoint = (point: Point, currentPointValue: any) => {
    let selectedPointsCopy = [...selectedPoints];

    selectedPointsCopy.filter((equip) => {
      if (point.equipRef.val === equip.id) {
        const selectedPoint = point;

        if (currentPointValue === false) {
          equip.selectedPoints = [...equip.selectedPoints, selectedPoint];
        } else {
          const selectedPointsArrayCopy = [...equip.selectedPoints];

          const filteredSelectedPoints = selectedPointsArrayCopy.filter(
            (selectedPointAfterUncheck) => {
              if (point.id.val !== selectedPointAfterUncheck.id.val) {
                return selectedPointAfterUncheck;
              }
            }
          );

          equip.selectedPoints = filteredSelectedPoints;
        }
      }
    });

    setSelectedPoints(selectedPointsCopy);
  };

  return (
    <PanelModelContext.Provider
      value={{ toggleSelectAll, selectPoint, selectedPoints }}
    >
      <Drawer anchor="right" open={state.panelModalVisible}>
        <ModalHeader
          title={!panel ? 'New Panel' : `Edit ${panel.Name}`}
          modalKey="panelModalVisible"
        />
        <form
          style={{ paddingBottom: 40 }}
          onSubmit={panel?.Name ? savePanel : addPanel}
        >
          <div>
            <InputLabel>
              Panel name
              <span>*</span>
            </InputLabel>
            <TextField
              inputProps={{
                ref: inputRef,
              }}
              variant="outlined"
              placeholder="Panel name"
              defaultValue={panel?.Name}
            />

            <Typography variant="h6">Panel points</Typography>
            {state.hsData &&
              state.hsData.map((equip: Equip, equipIndex: number) => (
                <EquipItem key={'EquipItem-' + equipIndex} item={equip} />
              ))}
          </div>
          <div>
            {panel && (
              <Button
                variant="contained"
                fullWidth
                color="error"
                style={{ marginTop: 10 }}
                startIcon={<BinSvg />}
                onClick={deletePanel}
                disabled={state.loading}
              >
                Delete panel
              </Button>
            )}
            <Button
              variant="contained"
              carrier
              fullWidth
              startIcon={panel ? <SaveSvg /> : <PlusSvg />}
              type="submit"
              disabled={state.loading}
              style={{ marginTop: 10 }}
            >
              {panel ? 'Save panel' : 'Add panel'}
            </Button>
            {panel && (
              <Typography style={{ marginTop: 10 }}>
                <i> edit this panel will reset the custom order</i>
              </Typography>
            )}
          </div>
        </form>
      </Drawer>
    </PanelModelContext.Provider>
  );
};

export default PanelModal;

const EquipItem: React.FC<{ item: Equip }> = ({ item }) => {
  const { classes } = useStyle();
  const { toggleSelectAll, selectedPoints } = useContext(PanelModelContext);

  const currentSelectedPointsNb = useMemo(() => {
    let currentSelectedPoints = 0;
    selectedPoints.forEach((currentEquip: any) => {
      if (currentEquip.id === item.id?.val) {
        currentSelectedPoints = currentEquip.selectedPoints?.length;
      }
    });
    return currentSelectedPoints;
  }, [selectedPoints, item.id?.val]);

  const { value: opened, toggle } = useBoolean();

  return (
    <Accordion expanded={opened} className={classes.accordion}>
      <AccordionSummary className={classes.summary}>
        <div className={classes.summary}>
          <Typography
            variant="body1"
            style={{ height: 20, marginTop: 'auto', marginBottom: 'auto' }}
          >
            <IconButton size="small" onClick={toggle}>
              <ArrowDropDown fontSize="small" />
            </IconButton>
            <span>{item.dis}</span>
          </Typography>
          <div className={classes.buttons}>
            <div className={classes.buttons}>
              <Button onClick={() => toggleSelectAll(item, true)}>
                Select All
              </Button>
              <Button onClick={() => toggleSelectAll(item, false)}>
                Deselect All
              </Button>
            </div>
            <Typography style={{ height: 20, margin: 'auto' }}>
              {currentSelectedPointsNb} / {item.points && item.points.length}
            </Typography>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails style={{ paddingTop: 0 }}>
        <List dense style={{ paddingTop: 0 }}>
          {item.points?.map((point: any, index: number) => (
            <PanelModalPoint key={index} point={point} />
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

const PanelModalPoint = ({ point }: any) => {
  const { selectPoint, selectedPoints } = useContext(PanelModelContext);

  const currentPointValue = useMemo(() => {
    let currentValue = false;
    if (selectedPoints) {
      selectedPoints.filter((equip: any) => {
        if (point.equipRef.val === equip.id) {
          if (equip.selectedPoints.length) {
            equip.selectedPoints.forEach((selectedPoint: any) => {
              if (selectedPoint.id.val === point.id.val) {
                currentValue = true;
              }
            });
          }
        }
      });
    }
    return currentValue;
  }, [selectedPoints, point.id.val, point.equipRef.val]);

  return (
    <ListItem
      secondaryAction={
        <>
          {point.kind === 'Number' && point.writable && (
            <img src={PointExSlider} style={{ height: 15 }} alt="" />
          )}
          {point.kind === 'Bool' && !point.writable && (
            <img src={PointExStatus} style={{ height: 15 }} alt="" />
          )}
          {point.kind === 'Bool' && point.writable && (
            <img src={PointExSwitch} style={{ height: 15 }} alt="" />
          )}
          {point.kind === 'Number' && !point.writable && (
            <img src={PointExInput} style={{ height: 15 }} alt="" />
          )}
        </>
      }
    >
      <ListItemIcon>
        <Checkbox
          size="small"
          style={{ padding: 0 }}
          onChange={() => selectPoint(point, currentPointValue)}
          checked={currentPointValue}
        />
        <Typography variant="body1">{point.dis}</Typography>
      </ListItemIcon>
      <div
        style={{
          borderBottom: '1px dashed grey',
          flexGrow: 1,
        }}
      />
    </ListItem>
  );
};
