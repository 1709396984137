import React, { ReactElement } from 'react';
import { Header } from './Header';
import { Footer } from './Footer';
import { SettingsModal } from '../modals/SettingsModal';
import { makeStyles } from 'tss-react/mui';
import { Container } from '@mui/material';
import { useAppContext } from '../../context/AppContext';
import { Loader } from '../Loader';

const useStyle = makeStyles()(() => ({
  layout: {
    paddingTop: 80,
  },
}));

export const Layout: React.FC<{ children: ReactElement | ReactElement[] }> = ({
  children,
}) => {
  const [{ loading }] = useAppContext();
  const { classes } = useStyle();
  return (
    <div className={classes.layout}>
      {loading && <Loader />}
      <Header />
      <Container maxWidth="xl">{children}</Container>
      <Footer />
      <SettingsModal />
    </div>
  );
};
