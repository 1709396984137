import styled from '@emotion/styled';

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  min-height: calc(100% - 100px);
  height: fit-content;
  width: 100%;
  justify-content: space-between;
  padding-bottom: 20px;
  position: relative;
`;
