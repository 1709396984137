import { palette } from './palette'
import { typography } from './typography'
import { breakpoints } from './breakpoints'
import { components } from './components'
import * as others from './others'
export * from './globalStyles'

export const defaultTheme: any = {
    palette,
    typography,
    breakpoints,
    components,
    ...others
}